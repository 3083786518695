var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { withParentDimensions, } from '../generic/withParentDimensions';
import { area, curveLinear, stack } from 'd3-shape';
import { scaleLinear } from 'd3-scale';
import { getFill, theme } from '../../theme';
import { GraphSubTitle, GraphSVG, GraphTitle, } from '../common-styled-components';
import { Tick } from './Tick';
import { findIndex } from 'lodash';
import { Path } from '../generic/Path';
export var stackData = function (data, xKey, seriesKeys, scaleX, scaleY) {
    var stackableData = data.map(function (dataRow, i) {
        var rowStack = {};
        rowStack[xKey] = scaleX(i);
        return __assign(__assign({}, rowStack), seriesKeys.reduce(function (result, key) {
            var _a;
            return (__assign(__assign({}, result), (_a = {}, _a[key] = scaleY(dataRow[key]), _a)));
        }, {}));
    });
    // Create data stacks. The array is copied to prevent mutating original
    var stacker = stack().keys(seriesKeys);
    var stacks = stacker(stackableData);
    return stacks;
};
var ClusterSummaryGraph = function (_a) {
    var summaryData = _a.summaryData, title = _a.title, parentDimensions = _a.parentDimensions, positiveKeys = _a.positiveKeys, negativeKeys = _a.negativeKeys, hideAxis = _a.hideAxis, positiveMax = _a.positiveMax, negativeMax = _a.negativeMax, showAmount = _a.showAmount;
    var height = parentDimensions.width * 0.6;
    var margins = {
        top: 5,
        bottom: 20,
        right: 10,
        left: hideAxis ? 0 : 55,
    };
    var scaleX = scaleLinear()
        .range([margins.left, parentDimensions.width - margins.right])
        .domain([0, summaryData.length - 1]);
    var positiveMaxValue = positiveMax !== undefined
        ? positiveMax
        : Math.max.apply(Math, summaryData.map(function (d) { return d.tyotulot_yhteensa_ka; }));
    var negativeMaxValue = negativeMax !== undefined
        ? negativeMax
        : Math.max.apply(Math, summaryData.map(function (d) { return d.etuudet_yhteensa_ka; }));
    var maxAmount = positiveMaxValue + negativeMaxValue;
    var contentHeight = height - margins.bottom - margins.top;
    var zeroLine = height -
        margins.bottom -
        (negativeMaxValue / (negativeMaxValue + positiveMaxValue)) * contentHeight;
    var positiveContentHeight = (positiveMaxValue / maxAmount) * contentHeight;
    var negativeContentHeight = (negativeMaxValue / maxAmount) * contentHeight;
    var positiveStackScale = scaleLinear()
        .range([0, positiveContentHeight])
        .domain([0, positiveMaxValue]);
    var negativeStackScale = scaleLinear()
        .range([0, negativeContentHeight])
        .domain([0, negativeMaxValue]);
    var positiveDataStacks = stackData(summaryData, 'kuukausi', positiveKeys, scaleX, positiveStackScale);
    var negativeDataStacks = stackData(summaryData, 'kuukausi', negativeKeys, scaleX, negativeStackScale);
    var positiveAreaGenerator = area()
        .x(function (d) { return d.data['kuukausi']; })
        .y0(function (d) { return zeroLine - d[0]; })
        .y1(function (d) { return zeroLine - d[1]; })
        .curve(curveLinear);
    var negativeAreaGenerator = area()
        .x(function (d) { return d.data['kuukausi']; })
        .y0(function (d) { return zeroLine + d[0]; })
        .y1(function (d) { return zeroLine + d[1]; })
        .curve(curveLinear);
    var findX = function (month) {
        return scaleX(findIndex(summaryData, function (d) { return d.kuukausi === month; }));
    };
    return (_jsxs("div", { children: [title ? _jsx(GraphTitle, { children: title }, void 0) : undefined,
            showAmount ? (_jsxs(GraphSubTitle, { children: [summaryData[0].henkiloita.toLocaleString('fi-FI'), " henkil\u00F6\u00E4"] }, void 0)) : undefined,
            _jsxs(GraphSVG, __assign({ width: parentDimensions.width, height: height }, { children: [negativeDataStacks.map(function (stack) {
                        return (_jsx(Path, { fill: getFill(stack.key), d: negativeAreaGenerator(stack) || undefined }, stack.key));
                    }),
                    positiveDataStacks.map(function (stack) {
                        return (_jsx(Path, { fill: getFill(stack.key), d: positiveAreaGenerator(stack) || undefined }, stack.key));
                    }),
                    hideAxis ? (_jsx("g", {}, void 0)) : (_jsxs(_Fragment, { children: [_jsxs(Tick, __assign({ side: 'left', x: margins.left, y: margins.top }, { children: [positiveMaxValue.toLocaleString('fi-FI', {
                                        maximumFractionDigits: 0,
                                    }), ' ', "\u20AC"] }), void 0),
                            _jsx(Tick, __assign({ side: 'left', x: margins.left, y: zeroLine - 6, hideLine: true }, { children: "Ty\u00F6tulot" }), void 0),
                            _jsx("line", { x1: 0, x2: parentDimensions.width, y1: zeroLine, y2: zeroLine, stroke: theme.colors.grey(3), strokeWidth: 0.5 }, void 0),
                            _jsx(Tick, __assign({ side: 'left', x: margins.left, y: zeroLine + 6, hideLine: true }, { children: "Etuudet" }), void 0),
                            _jsxs(Tick, __assign({ side: 'left', x: margins.left, y: height - margins.bottom }, { children: [negativeMaxValue.toLocaleString('fi-FI', {
                                        maximumFractionDigits: 0,
                                    }), ' ', "\u20AC"] }), void 0),
                            _jsx(Tick, __assign({ side: 'bottom', x: findX('2005-01'), y: height - margins.bottom }, { children: "18 v" }), void 0),
                            _jsx(Tick, __assign({ side: 'bottom', x: findX('2015-01'), y: height - margins.bottom }, { children: "28 v" }), void 0)] }, void 0))] }), void 0)] }, void 0));
};
export default withParentDimensions(ClusterSummaryGraph);
