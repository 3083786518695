var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { theme } from '../../theme';
var TickText = styled.text(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), theme.fontLabel);
export var Tick = function (_a) {
    var side = _a.side, x = _a.x, y = _a.y, children = _a.children, hideLine = _a.hideLine;
    var fontSize = theme.fontSizeNumber(-2);
    var tickLength = side === 'left' ? 3 : 2 + fontSize;
    return (_jsxs("g", { children: [!hideLine ? (_jsx("line", { stroke: theme.colors.grey(3), strokeWidth: 1, x1: x, x2: side === 'left' ? x - tickLength : x, y1: y, y2: side === 'left' ? y : y + tickLength }, void 0)) : undefined,
            _jsx(TickText, __assign({ x: side === 'left' ? x - tickLength - 2 : x + 2, y: side === 'left' ? y + fontSize / 2 - 2 : y + fontSize, fontSize: fontSize, fill: theme.colors.grey(2), textAnchor: side === 'left' ? 'end' : 'start' }, { children: children }), void 0)] }, void 0));
};
var templateObject_1;
