var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import styled from 'styled-components';
var PathElement = styled.path(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  transition: opacity 2s;\n"], ["\n  transition: opacity 2s;\n"])));
export var Path = function (props) {
    var _a = useState(false), visible = _a[0], setVisible = _a[1];
    useEffect(function () { return setVisible(true); }, []);
    return _jsx(PathElement, __assign({}, props, { style: { opacity: visible ? 1 : 0 } }), void 0);
};
var templateObject_1;
