var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { breakpoints, theme } from '../theme';
export var ArticleMain = styled.section(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 1024px;\n  padding: ", ";\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n"], ["\n  width: 100%;\n  max-width: 1024px;\n  padding: ", ";\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n"])), theme.spacing(4));
export var TextContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  max-width: 640px;\n  width: 100%;\n"], ["\n  max-width: 640px;\n  width: 100%;\n"])));
export var WideTextContent = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
export var AppTitle = styled.h1(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin: 0 auto ", " auto;\n  text-align: center;\n  ", ";\n  ", ";\n  color: white;\n  @media (max-width: ", "px) {\n    margin: 0 auto ", " auto;\n    ", ";\n  }\n"], ["\n  margin: 0 auto ", " auto;\n  text-align: center;\n  ", ";\n  ", ";\n  color: white;\n  @media (max-width: ", "px) {\n    margin: 0 auto ", " auto;\n    ", ";\n  }\n"])), theme.spacing(5), theme.fontBold, theme.fontSize(7), breakpoints.tablet, theme.spacing(4), theme.fontSize(5));
export var AppSubTitle = styled.h2(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  margin: 0 auto ", " auto;\n  color: ", ";\n  text-align: center;\n  max-width: 720px;\n  padding: 0 ", ";\n  @media (max-width: ", "px) {\n    ", ";\n  }\n  @media (max-width: ", "px) {\n    ", ";\n  }\n"], ["\n  ", ";\n  ", ";\n  margin: 0 auto ", " auto;\n  color: ", ";\n  text-align: center;\n  max-width: 720px;\n  padding: 0 ", ";\n  @media (max-width: ", "px) {\n    ", ";\n  }\n  @media (max-width: ", "px) {\n    ", ";\n  }\n"])), theme.fontNormal, theme.fontSize(3), theme.spacing(4), theme.colors.lightGrey, theme.spacing(6), breakpoints.tablet, theme.fontSize(2), breakpoints.mobile, theme.fontSize(1));
export var SectionTitle = styled.h2(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  margin: ", " 0 ", " 0;\n"], ["\n  ", ";\n  ", ";\n  margin: ", " 0 ", " 0;\n"])), theme.fontBold, theme.fontSize(3), theme.spacing(5), theme.spacing(3));
export var ArticleSubtitle = styled.h3(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  margin: ", " 0 ", " 0;\n"], ["\n  ", ";\n  ", ";\n  margin: ", " 0 ", " 0;\n"])), theme.fontBold, theme.fontSize(1), theme.spacing(4), theme.spacing(3));
export var ArticleSubtitleNoMargin = styled(ArticleSubtitle)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin: 0;\n"], ["\n  margin: 0;\n"])));
export var LeadText = styled.p(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  margin: 0 0 ", " 0;\n  line-height: 1.6;\n"], ["\n  ", ";\n  ", ";\n  margin: 0 0 ", " 0;\n  line-height: 1.6;\n"])), theme.fontNormal, theme.fontSize(1), theme.spacing(3));
export var P = styled.p(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  margin: 0 0 ", " 0;\n  line-height: 1.6;\n"], ["\n  ", ";\n  ", ";\n  margin: 0 0 ", " 0;\n  line-height: 1.6;\n"])), theme.fontNormal, theme.fontSize(0), theme.spacing(3));
export var ShareLink = styled(P)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  ", ";\n  color: ", ";\n  cursor: pointer;\n"], ["\n  ", ";\n  color: ", ";\n  cursor: pointer;\n"])), theme.fontBold, theme.colors.blue);
export var A = styled.a(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  color: ", ";\n  text-decoration: underline;\n  text-decoration-color: ", ";\n\n  &:active {\n    text-decoration-color: ", ";\n  }\n  &:visited {\n    text-decoration-color: ", ";\n  }\n"], ["\n  color: ", ";\n  text-decoration: underline;\n  text-decoration-color: ", ";\n\n  &:active {\n    text-decoration-color: ", ";\n  }\n  &:visited {\n    text-decoration-color: ", ";\n  }\n"])), theme.colors.blue, theme.colors.blue, theme.colors.lightBlue, theme.colors.blue);
export var Li = styled.li(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  line-height: 1.6;\n  margin-bottom: ", ";\n"], ["\n  ", ";\n  ", ";\n  line-height: 1.6;\n  margin-bottom: ", ";\n"])), theme.fontNormal, theme.fontSize(0), theme.spacing(1));
export var Bold = styled.span(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), theme.fontBold);
export var Italic = styled.span(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  font-style: italic;\n"], ["\n  font-style: italic;\n"])));
export var GraphTitle = styled.h4(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  margin: 0;\n"], ["\n  ", ";\n  ", ";\n  margin: 0;\n"])), theme.fontLabelBold, theme.fontSize(0));
export var FactBoxTitle = styled.h4(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  margin: 0 0 ", " 0;\n"], ["\n  ", ";\n  ", ";\n  margin: 0 0 ", " 0;\n"])), theme.fontLabelBold, theme.fontSize(0), theme.spacing(3));
export var FactBoxTitleNoMargin = styled(FactBoxTitle)(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  margin: 0;\n"], ["\n  margin: 0;\n"])));
export var GraphSubTitle = styled.h5(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  margin: 0 0 ", " 0;\n"], ["\n  ", ";\n  ", ";\n  margin: 0 0 ", " 0;\n"])), theme.fontNormal, theme.fontSize(-1), theme.spacing(2));
export var GraphSVG = styled.svg(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  margin-top: ", ";\n"], ["\n  margin-top: ", ";\n"])), theme.spacing(2));
export var Caption = styled.div(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  width: 100%;\n  ", ";\n  ", ";\n  color: ", ";\n"], ["\n  width: 100%;\n  ", ";\n  ", ";\n  color: ", ";\n"])), theme.fontNormal, theme.fontSize(-1), theme.colors.blue);
export var TextComment = styled.span(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  color: red;\n"], ["\n  color: red;\n"])));
export var Grid = styled.div(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(\n    auto-fill,\n    minmax(220px, ", ")\n  );\n  grid-gap: ", ";\n  margin: ", " 0;\n  align-items: end;\n"], ["\n  display: grid;\n  grid-template-columns: repeat(\n    auto-fill,\n    minmax(220px, ", ")\n  );\n  grid-gap: ", ";\n  margin: ", " 0;\n  align-items: end;\n"])), function (p) { return (p.full ? '100%' : '1fr'); }, theme.spacing(2), theme.spacing(2));
export var Legend = styled.div(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n  margin: ", " 0;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  min-height: 40px;\n"], ["\n  margin: ", " 0;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  min-height: 40px;\n"])), theme.spacing(2));
var LegendItemElement = styled.div(templateObject_25 || (templateObject_25 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  margin-right: ", ";\n  margin-top: ", ";\n  ", ";\n  ", ";\n"], ["\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  margin-right: ", ";\n  margin-top: ", ";\n  ", ";\n  ", ";\n"])), theme.spacing(1), theme.spacing(1), theme.fontSize(-2), theme.fontLabelBold);
var ColorMarker = styled.div(templateObject_26 || (templateObject_26 = __makeTemplateObject(["\n  width: 12px;\n  height: 12px;\n  background-color: ", ";\n  margin-right: ", ";\n"], ["\n  width: 12px;\n  height: 12px;\n  background-color: ", ";\n  margin-right: ", ";\n"])), function (p) { return p.color; }, theme.spacing(0));
export var LegendItem = function (props) { return (_jsxs(LegendItemElement, { children: [_jsx(ColorMarker, { color: props.color }, void 0),
        _jsx("div", { children: props.text }, void 0)] }, void 0)); };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24, templateObject_25, templateObject_26;
