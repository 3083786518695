var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { withParentDimensions, } from '../generic/withParentDimensions';
import { area, curveLinear, stack } from 'd3-shape';
import { scaleLinear } from 'd3-scale';
import { getFill, theme } from '../../theme';
import { GraphSVG, GraphTitle } from '../common-styled-components';
import { Tick } from './Tick';
import { findIndex } from 'lodash';
import { Path } from '../generic/Path';
import styled from 'styled-components';
export var stackData = function (data, xKey, seriesKeys, scaleX, scaleY) {
    var stackableData = data.map(function (dataRow, i) {
        var rowStack = {};
        rowStack[xKey] = scaleX(i);
        return __assign(__assign({}, rowStack), seriesKeys.reduce(function (result, key) {
            var _a;
            return (__assign(__assign({}, result), (_a = {}, _a[key] = scaleY(dataRow[key]), _a)));
        }, {}));
    });
    // Create data stacks. The array is copied to prevent mutating original
    var stacker = stack().keys(seriesKeys);
    var stacks = stacker(stackableData);
    return stacks;
};
var Clip = styled.g(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  transition: clip-path 500ms;\n  clip-path: polygon(\n    ", "% 0%,\n    ", "% 0%,\n    ", "% 100%,\n    ", "% 100%\n  );\n"], ["\n  transition: clip-path 500ms;\n  clip-path: polygon(\n    ", "% 0%,\n    ", "% 0%,\n    ", "% 100%,\n    ", "% 100%\n  );\n"])), function (p) { return p.x1 * 100; }, function (p) { return p.x2 * 100; }, function (p) { return p.x2 * 100; }, function (p) { return p.x1 * 100; });
var ClusterAccountUseGraph = function (_a) {
    var clusterData = _a.clusterData, title = _a.title, parentDimensions = _a.parentDimensions, lastShownIndex = _a.lastShownIndex, shownSeries = _a.shownSeries;
    var height = parentDimensions.width * 0.6;
    var margins = {
        top: 5,
        bottom: 20,
        right: 10,
        left: 50,
    };
    var scaleX = scaleLinear()
        .range([margins.left, parentDimensions.width - margins.right])
        .domain([0, clusterData.length - 1]);
    var stackableData = clusterData.map(function (datum) {
        return __assign(__assign({}, datum), { tili_kaytossa: datum.henkiloita - datum.tili_nollilla - datum.tili_taynna });
    });
    var stackScale = scaleLinear()
        .range([0, height - margins.bottom - margins.top])
        .domain([0, stackableData[0].henkiloita]);
    var scaleToMargins = scaleLinear()
        .range([margins.top, height - margins.bottom])
        .domain([height - margins.bottom - margins.top, 0]);
    var dataStacks = stackData(stackableData, 'kuukausi', shownSeries || ['tili_taynna', 'tili_kaytossa', 'tili_nollilla'], scaleX, stackScale);
    var areaGenerator = area()
        .x(function (d) { return d.data['kuukausi']; })
        .y0(function (d) { return scaleToMargins(d[0]); })
        .y1(function (d) { return scaleToMargins(d[1]); })
        .curve(curveLinear);
    var findX = function (month) {
        return scaleX(findIndex(clusterData, function (d) { return d.kuukausi === month; }));
    };
    return (_jsxs("div", { children: [_jsx(GraphTitle, { children: title }, void 0),
            _jsxs(GraphSVG, __assign({ width: parentDimensions.width, height: height }, { children: [_jsx(Clip, __assign({ x1: 0, x2: lastShownIndex !== undefined
                            ? lastShownIndex / (clusterData.length - 1)
                            : 100 }, { children: dataStacks.map(function (stack) {
                            return (_jsx(Path, { fill: getFill(stack.key), d: areaGenerator(stack) || undefined }, stack.key));
                        }) }), void 0),
                    _jsx("line", { x1: margins.left, x2: margins.left, y1: margins.top, y2: height - margins.bottom, stroke: theme.colors.grey(3), strokeWidth: 1 }, void 0),
                    _jsx(Tick, __assign({ side: 'left', x: margins.left, y: margins.top }, { children: clusterData[0].henkiloita.toLocaleString('fi-FI') }), void 0),
                    _jsx(Tick, __assign({ side: 'left', x: margins.left, y: height - margins.bottom }, { children: "0" }), void 0),
                    _jsx("line", { x1: margins.left, x2: parentDimensions.width - margins.right, y1: height - margins.bottom, y2: height - margins.bottom, stroke: theme.colors.grey(3), strokeWidth: 1 }, void 0),
                    _jsx(Tick, __assign({ side: 'bottom', x: findX('2005-01'), y: height - margins.bottom }, { children: "18 v" }), void 0),
                    _jsx(Tick, __assign({ side: 'bottom', x: findX('2010-01'), y: height - margins.bottom }, { children: "23 v" }), void 0),
                    _jsx(Tick, __assign({ side: 'bottom', x: findX('2015-01'), y: height - margins.bottom }, { children: "28 v" }), void 0)] }), void 0)] }, void 0));
};
export default withParentDimensions(ClusterAccountUseGraph);
var templateObject_1;
