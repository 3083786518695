var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { scaleSqrt } from 'd3-scale';
import styled from 'styled-components';
import { getFill, theme } from '../../theme';
import { Circle } from '../generic/Circle';
import { withParentDimensions, } from '../generic/withParentDimensions';
var Balance = styled.text(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  fill: ", ";\n  text-anchor: middle;\n"], ["\n  ", ";\n  ", ";\n  fill: ", ";\n  text-anchor: middle;\n"])), theme.fontBold, theme.fontSize(2), function (p) { return (p.negative ? theme.colors.lightGrey : theme.colors.blue); });
var BaseAccountExplanation = function (props) {
    var balance = props.balance, width = props.parentDimensions.width;
    var height = Math.min(width, 400);
    var sizeScale = scaleSqrt()
        .range([3, height / 2])
        .domain([0, 20000]);
    var fill = function () {
        if (balance === 20000)
            return getFill('tili_taynna');
        if (balance === 0)
            return getFill('tili_nollilla');
        return getFill('tili_kaytossa');
    };
    return (_jsxs("svg", __assign({ width: width, height: height }, { children: [_jsx(Circle, { cx: width / 2, cy: height / 2, r: sizeScale(balance), fill: fill() }, void 0),
            _jsxs(Balance, __assign({ x: width / 2 + 2, y: height / 2 - 5, negative: balance === 20000 }, { children: [balance.toLocaleString('fi-FI'), " \u20AC"] }), void 0)] }), void 0));
};
export default withParentDimensions(BaseAccountExplanation);
var templateObject_1;
