var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import styled from 'styled-components';
import { AppContext, partialUpdate } from '../context';
import { texts } from '../texts';
import { theme } from '../theme';
import { dataSetNames, isCluster, isDataSet, isLimitation, isThreshold, isTool, } from '../types';
import { Link, useLocation } from 'react-router-dom';
var getOptions = function (type) {
    switch (type) {
        case 'clusters':
            return [
                {
                    value: 'Childcare',
                    label: texts['Childcare'],
                },
                {
                    value: 'Deceased',
                    label: texts['Deceased'],
                },
                {
                    value: 'Education/with study benefit',
                    label: texts['Education/with study benefit'],
                },
                {
                    value: 'Employment after difficulties',
                    label: texts['Employment after difficulties'],
                },
                {
                    value: 'Employment/with study benefit',
                    label: texts['Employment/with study benefit'],
                },
                {
                    value: 'Fragmented employment / unemployment with secondary lvl study bnf',
                    label: texts['Fragmented employment / unemployment with secondary lvl study bnf'],
                },
                {
                    value: 'Fragmented no data / education / employment',
                    label: texts['Fragmented no data / education / employment'],
                },
                {
                    value: 'Fragmented unemployment / income support',
                    label: texts['Fragmented unemployment / income support'],
                },
                {
                    value: 'Not much or no data',
                    label: texts['Not much or no data'],
                },
                {
                    value: 'yhteensa',
                    label: texts['yhteensa'],
                },
            ];
        case 'dataSet':
            return dataSetNames.map(function (d) { return ({ value: d, label: d }); });
        case 'threshold':
            return [
                {
                    value: 'Ei kynnystä',
                    label: 'Ei kynnystä',
                },
                {
                    value: 'Kynnys',
                    label: 'Kynnys',
                },
            ];
        case 'limitation':
            return [
                {
                    value: 'Ei rajoitettu',
                    label: 'Ei rajoitettu',
                },
                {
                    value: 'Rajoitettu',
                    label: 'Rajoitettu',
                },
            ];
        case 'section':
            return [
                {
                    value: '/',
                    label: 'Artikkeli',
                },
                {
                    value: '/sanottua',
                    label: 'Sanottua',
                },
                {
                    value: '/tyokalut',
                    label: 'Lisätyökalut',
                },
            ];
        case 'tool':
            return [
                {
                    value: 'accountUse',
                    label: 'Tilin käyttö',
                },
                {
                    value: 'accountBalance',
                    label: 'Tilin saldo',
                },
                {
                    value: 'roleSummary',
                    label: 'Toiminta ryhmittäin',
                },
            ];
    }
};
var NaviMain = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var NaviTitle = styled.h4(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  color: ", ";\n  ", ";\n  margin: 0 0 ", " 0;\n"], ["\n  ", ";\n  color: ", ";\n  ", ";\n  margin: 0 0 ", " 0;\n"])), theme.fontLabelBold, theme.colors.blackText, theme.fontSize(0), theme.spacing(2));
var NaviList = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: ", ";\n"], ["\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: ", ";\n"])), function (p) { return (p.center ? 'center' : 'flex-start'); });
var NaviItem = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  ", ";\n  ", ";\n  padding: ", " 0;\n  margin: 0 ", " 0 0;\n  white-space: nowrap;\n  cursor: pointer;\n"], ["\n  color: ",
    ";\n  ", ";\n  ", ";\n  padding: ", " 0;\n  margin: 0 ", " 0 0;\n  white-space: nowrap;\n  cursor: pointer;\n"])), function (p) {
    return p.negative
        ? p.selected
            ? theme.colors.white
            : theme.colors.grey(3)
        : p.selected
            ? theme.colors.blue
            : theme.colors.grey(3);
}, theme.fontLabelBold, theme.fontSize(-1), theme.spacing(2), theme.spacing(2));
var NaviLink = styled(Link)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  text-decoration: none;\n"], ["\n  text-decoration: none;\n"])));
export var MainNavigation = function (_a) {
    var type = _a.type, align = _a.align, negative = _a.negative;
    var location = useLocation();
    return (_jsx(NaviMain, { children: _jsx(NaviList, __assign({ center: align === 'center' }, { children: getOptions(type).map(function (option) {
                return (_jsx(NaviLink, __assign({ to: option.value }, { children: _jsx(NaviItem, __assign({ selected: option.value === '/'
                            ? location.pathname === '/'
                            : location.pathname.includes(option.value), negative: negative }, { children: option.label }), void 0) }), option.value));
            }) }), void 0) }, void 0));
};
export var Navigation = function (_a) {
    var type = _a.type, align = _a.align, negative = _a.negative;
    var _b = useContext(AppContext), selections = _b.selections, setSelections = _b.setSelections;
    var selectValue = function (value) {
        if (type === 'clusters' && isCluster(value)) {
            if (selections.selectedClusters.includes(value)) {
                setSelections(partialUpdate(selections, {
                    selectedClusters: selections.selectedClusters.filter(function (cluster) { return cluster !== value; }),
                }));
            }
            else {
                setSelections(partialUpdate(selections, {
                    selectedClusters: selections.selectedClusters.concat([value]),
                }));
            }
        }
        if (type === 'tool' && isTool(value)) {
            if (selections.selectedTool !== value) {
                setSelections(partialUpdate(selections, {
                    selectedTool: value,
                }));
            }
        }
        if (type === 'dataSet' && isDataSet(value)) {
            if (selections.selectedDataSet !== value) {
                setSelections(partialUpdate(selections, {
                    selectedDataSet: value,
                }));
            }
        }
        if (type === 'threshold' && isThreshold(value)) {
            if (selections.selectedThreshold !== value) {
                setSelections(partialUpdate(selections, {
                    selectedThreshold: value,
                }));
            }
        }
        if (type === 'limitation' && isLimitation(value)) {
            if (selections.selectedLimitation !== value) {
                setSelections(partialUpdate(selections, {
                    selectedLimitation: value,
                }));
            }
        }
    };
    var isSelected = function (value) {
        if (type === 'clusters' && isCluster(value)) {
            return selections.selectedClusters.includes(value);
        }
        if (type === 'dataSet' && isDataSet(value)) {
            return selections.selectedDataSet === value;
        }
        if (type === 'threshold' && isThreshold(value)) {
            return selections.selectedThreshold === value;
        }
        if (type === 'limitation' && isLimitation(value)) {
            return selections.selectedLimitation === value;
        }
        if (type === 'tool' && isTool(value)) {
            return selections.selectedTool === value;
        }
        return false;
    };
    return (_jsxs(NaviMain, { children: [texts[type] && _jsx(NaviTitle, { children: texts[type] }, void 0),
            _jsx(NaviList, __assign({ center: align === 'center' }, { children: getOptions(type).map(function (option) {
                    return (_jsx(NaviItem, __assign({ selected: isSelected(option.value), negative: negative, onClick: function () { return selectValue(option.value); } }, { children: option.label }), option.value));
                }) }), void 0)] }, void 0));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
