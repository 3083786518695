var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { breakpoints, theme } from '../theme';
import { A, ArticleMain, ArticleSubtitle, Bold, Caption, FactBoxTitle, GraphTitle, Italic, LeadText, Legend, LegendItem, Li, P, SectionTitle, ShareLink, TextContent, WideTextContent, } from './common-styled-components';
import 'intersection-observer';
import scrollama from 'scrollama';
import { ClusterSummaryGrid } from './graphs/ClusterSummaryGrid';
import ClusterAccountUseGraph from './graphs/ClusterAccountUseGraph';
import { texts } from '../texts';
import { AppContext } from '../context';
import BaseAccountExplanation from './graphs/BaseAccountExplanation';
import FactBox from './generic/FactBox';
import { ClusterAccountUseGrid } from './graphs/ClusterAccountUseGrid';
import uiExamplePic from '../../images/perustilinosto.png';
import { fetchDataSet } from '../data-handlers/resolve-data';
import { Spinner } from './generic/Spinner';
import { shareToClipBoard } from '../utils';
var FigureTextContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  @media (min-width: ", "px) {\n    padding-left: calc(66% + ", ");\n    max-width: 100%;\n    align-items: flex-start;\n  }\n"], ["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  @media (min-width: ", "px) {\n    padding-left: calc(66% + ", ");\n    max-width: 100%;\n    align-items: flex-start;\n  }\n"])), breakpoints.tablet, theme.spacing(3));
var TextBox = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  ", ";\n  padding-bottom: ", ";\n  max-width: 400px;\n\n  &:first-child {\n    margin-top: -", ";\n  }\n\n  &:last-child {\n    padding-bottom: ", ";\n  }\n\n  & > p {\n    padding: ", " ", ";\n    background-color: rgba(255, 255, 255, 0.85);\n    margin: 0;\n  }\n\n  @media (min-width: ", "px) {\n    & > p {\n      padding: ", " 0;\n      background-color: transparent;\n    }\n  }\n"], ["\n  position: relative;\n  ", ";\n  padding-bottom: ", ";\n  max-width: 400px;\n\n  &:first-child {\n    margin-top: -", ";\n  }\n\n  &:last-child {\n    padding-bottom: ", ";\n  }\n\n  & > p {\n    padding: ", " ", ";\n    background-color: rgba(255, 255, 255, 0.85);\n    margin: 0;\n  }\n\n  @media (min-width: ", "px) {\n    & > p {\n      padding: ", " 0;\n      background-color: transparent;\n    }\n  }\n"])), theme.fontNormal, theme.spacing(10), theme.spacing(7), theme.spacing(7), theme.spacing(2), theme.spacing(3), breakpoints.tablet, theme.spacing(2));
var Figure = styled.figure(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n  margin: ", " 0;\n  padding: ", " 0;\n  max-width: 100%;\n  z-index: -1;\n\n  @media (min-width: ", "px) {\n    max-width: 66%;\n  }\n"], ["\n  ",
    "\n  margin: ", " 0;\n  padding: ", " 0;\n  max-width: 100%;\n  z-index: -1;\n\n  @media (min-width: ", "px) {\n    max-width: 66%;\n  }\n"])), function (p) {
    return p.sticky
        ? "\n          position: sticky;\n          top: 0;\n        "
        : 'position: relative;';
}, theme.spacing(4), theme.spacing(4), breakpoints.tablet);
var UIExampleContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 320px;\n  margin: ", " 0;\n"], ["\n  width: 100%;\n  max-width: 320px;\n  margin: ", " 0;\n"])), theme.spacing(4));
var UIExample = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  margin-bottom: ", ";\n"], ["\n  width: 100%;\n  margin-bottom: ", ";\n"])), theme.spacing(2));
var ShareButton = function () {
    var _a = useState(false), shared = _a[0], setShared = _a[1];
    return (_jsx(ShareLink, __assign({ onClick: function () {
            shareToClipBoard("https://perustilisimulaatio.fi");
            setShared(true);
        } }, { children: !shared ? 'Jaa tämä artikkeli' : 'Linkki kopioitu leikepöydälle!' }), void 0));
};
export default (function () {
    var _a = useContext(AppContext), dataSets = _a.dataSets, setDataSets = _a.setDataSets, summaryData = _a.summaryData;
    var articleDataSet = 'Toteutuneen mukainen, 650 € opintoraha';
    var articleThreshold = 'Kynnys';
    var articleLimitation = 'Rajoitettu';
    var _b = useState(), articleData = _b[0], setArticleData = _b[1];
    useEffect(function () {
        if (dataSets &&
            !dataSets[articleDataSet][articleThreshold][articleLimitation]) {
            fetchDataSet(dataSets, setDataSets, articleDataSet, articleThreshold, articleLimitation);
        }
    }, []);
    useEffect(function () {
        setArticleData(dataSets[articleDataSet][articleThreshold][articleLimitation]);
    }, [dataSets]);
    var scroller = useRef(scrollama());
    var _c = useState(0), summaryChartFrame = _c[0], setSummaryChartFrame = _c[1];
    var _d = useState({
        shownSeries: ['tili_nollilla'],
        lastShownIndex: 12,
    }), useChartState = _d[0], setUseChartState = _d[1];
    var _e = useState(0), baseAccountBalance = _e[0], setBaseAccountBalance = _e[1];
    useEffect(function () {
        // setup the instance, pass callback functions
        scroller.current
            .setup({
            step: '.step',
            offset: 0.7,
        })
            .onStepEnter(function (response) {
            switch (response.element.dataset.step) {
                case 'lifespan-enter':
                    setSummaryChartFrame(0);
                    break;
                case 'lifespan-study':
                    setSummaryChartFrame(1);
                    break;
                case 'lifespan-unemployment':
                    setSummaryChartFrame(2);
                    break;
                case 'lifespan-pay':
                    setSummaryChartFrame(3);
                    break;
                case 'lifespan-cumulative':
                    setSummaryChartFrame(4);
                    break;
                case 'baseAccountExplanation-start':
                    setBaseAccountBalance(0);
                    break;
                case 'baseAccountExplanation-fill':
                    setBaseAccountBalance(20000);
                    break;
                case 'baseAccountExplanation-use-1y':
                    setBaseAccountBalance(14750);
                    break;
                case 'baseAccountExplanation-empty':
                    setBaseAccountBalance(0);
                    break;
                case 'baseAccountExplanation-refill':
                    setBaseAccountBalance(1500);
                    break;
                case 'simulation-example-empty':
                    setUseChartState({
                        lastShownIndex: 12,
                        shownSeries: ['tili_nollilla'],
                    });
                    break;
                case 'simulation-example-empty-2':
                    setUseChartState({
                        lastShownIndex: 61,
                        shownSeries: ['tili_nollilla'],
                    });
                    break;
                case 'simulation-example-empty-3':
                    setUseChartState({
                        lastShownIndex: 132,
                        shownSeries: ['tili_nollilla'],
                    });
                    break;
                case 'simulation-example-account-full':
                    setUseChartState({
                        lastShownIndex: 132,
                        shownSeries: ['tili_taynna'],
                    });
                    break;
                case 'simulation-example-full-picture':
                    setUseChartState({
                        lastShownIndex: 132,
                        shownSeries: ['tili_taynna', 'tili_kaytossa', 'tili_nollilla'],
                    });
                    break;
            }
        })
            .onStepExit(function (_response) { return null; });
        // setup resize event
        window.addEventListener('resize', scroller.current.resize);
    }, []);
    var shownClusters = [
        'Education/with study benefit',
        'Employment/with study benefit',
        'Fragmented unemployment / income support',
        'Fragmented employment / unemployment with secondary lvl study bnf',
    ];
    var downloadURL = 'https://itla.fi/file/perustili-tie-kannustavaan-ja-lapinakyvaan-sosiaaliturvaan/';
    return (_jsxs(ArticleMain, { children: [_jsxs(TextContent, { children: [_jsx(LeadText, { children: "Nykyinen sosiaaliturva ei vastaa ta\u0308ma\u0308n pa\u0308iva\u0308n haasteisiin. Tarvitaan uusia innovaatioita, jotta etuuksien ja tyo\u0308n yhteensovittamisen ongelmat voidaan ratkaista. Ta\u0308ssa\u0308 artikkelissa esittelemme realistisen perustilimallin, joka mahdollistaa tyo\u0308markkinatuen, peruspa\u0308iva\u0308rahan ja opintorahan korvaamisen perustililla\u0308 ja sita\u0308 tukevalla osallistumistulolla. Mallissa etuuksien taso ei juurikaan muutu, mutta etuuksien oma hallinta yhdistettyna\u0308 automatisointiin tekee kansalaisten henkilo\u0308kohtaisesta ela\u0308ma\u0308nsuunnittelusta nykyista\u0308 selkea\u0308mpa\u0308a\u0308 ja lisa\u0308a\u0308 hyvinvoinnin kokemista." }, void 0),
                    _jsx(FactBox, { children: _jsxs(P, { children: ["T\u00E4m\u00E4 artikkeli on tiivistelm\u00E4 laajemmasta raportista", ' ', _jsx(Italic, { children: "Perustili \u2013 tie kannustavaan ja la\u0308pina\u0308kyva\u0308a\u0308n sosiaaliturvaan" }, void 0), ". Lataa raportti", ' ', _jsx(A, __assign({ href: downloadURL, target: "_blank" }, { children: _jsx(Bold, { children: "t\u00E4st\u00E4" }, void 0) }), void 0), "."] }, void 0) }, void 0),
                    _jsx(SectionTitle, { children: "Teknologia mahdollistaa sosiaaliturvan uudistuksen" }, void 0),
                    _jsx(P, { children: "Maailmanlaajuiset riskit - ilmastonmuutos, pandemiat ja finanssikriisit - ravistelevat vuoroillaan pohjoismaisen hyvinvointivaltion rakenteita. Kriisist\u00E4 kriisiin el\u00E4m\u00E4 edellytt\u00E4\u00E4 yhteiskunnalta notkeutta vastata nopeasti edess\u00E4 oleviin muutoksiin." }, void 0),
                    _jsx(P, { children: "Ty\u00F6ik\u00E4isen v\u00E4est\u00F6n kasvu on pys\u00E4htynyt. Vuoteen 2070 ulottuvan v\u00E4est\u00F6ennusteen mukaan 15\u201374-vuotiaiden m\u00E4\u00E4r\u00E4 laskee Suomessa n. 600 000 hengell\u00E4. Saman ennusteen mukaan 75 vuotta t\u00E4ytt\u00E4neiden m\u00E4\u00E4r\u00E4 kasvaa puolella miljoonalla. T\u00E4m\u00E4 luo paineita hyvinvointivaltion rahoitukselle ja yh\u00E4 useamman ty\u00F6ik\u00E4isen tulisi p\u00E4\u00E4st\u00E4 kest\u00E4v\u00E4lle ty\u00F6uralle." }, void 0),
                    _jsx(P, { children: "Samaan aikaan uudet digitaaliset alustat muokkaavat ty\u00F6el\u00E4m\u00E4\u00E4 ennenn\u00E4kem\u00E4tt\u00F6m\u00E4ll\u00E4 tavalla. Ty\u00F6st\u00E4 on tullut joustavampaa, mutta my\u00F6s ep\u00E4varmaa sek\u00E4 henkil\u00F6kohtaisesti ett\u00E4 ty\u00F6markkinoiden tasolla." }, void 0),
                    _jsxs(P, { children: ["N\u00E4it\u00E4 kehityskaaria vasten perustettu sosiaaliturvakomitea on kartoittanut", ' ', _jsx(A, __assign({ href: "https://stm.fi/sosiaaliturvauudistus", target: "_blank" }, { children: "sosiaaliturvan haasteita" }), void 0), ", jotka voidaan tiivist\u00E4\u00E4 nelj\u00E4\u00E4n p\u00E4\u00E4kohtaan:"] }, void 0),
                    _jsxs("ul", { children: [_jsx(Li, { children: "Ty\u00F6n ja sosiaaliturvan yhteensovittaminen" }, void 0),
                            _jsx(Li, { children: "Monimutkaisuus ja byrokraattisuus" }, void 0),
                            _jsx(Li, { children: "Palveluiden ja etuuksien yhteensovittaminen" }, void 0),
                            _jsx(Li, { children: "Asuminen ja viimesijainen turva" }, void 0)] }, void 0),
                    _jsx(ArticleSubtitle, { children: "Perustili: sosiaaliturvan innovaatio" }, void 0),
                    _jsx(P, { children: "Nykyinen sosiaaliturva ei vastaa ta\u0308ma\u0308n pa\u0308iva\u0308n haasteisiin. Tarvitaan uusia innovaatioita, jotta etuuksien ja tyo\u0308n yhteensovittamisen ongelmat voidaan ratkaista." }, void 0),
                    _jsx(P, { children: "Erilaisia tilimalleja \u2012 kuten tyo\u0308tto\u0308myystili, koulutustili tai terveystili \u2012 kehitta\u0308ma\u0308lla\u0308 on pyritty sosiaaliturvan ja kannustavuuden yhteensovittamiseen. Kaikkien tilien perusajatuksena on, etta\u0308 yksilo\u0308t \u201Cvakuuttavat itsensa\u0308\u201D riskien varalta elinkaaren yli. Tilimallit ovat ja\u0308a\u0308neet abstrakteiksi visioiksi paljolti siksi, ett\u00E4 teknologiset edellytykset eiv\u00E4t ole aiemmin mahdollistaneet mallien simulointia ja ka\u0308ytto\u0308o\u0308nottoa." }, void 0),
                    _jsxs(P, { children: ["Ta\u0308ssa\u0308 raportissa esitella\u0308a\u0308n perustilimalli, joka pyrkii olemaan seka\u0308 realistinen etta\u0308 tarpeeksi yksinkertainen, jotta se voidaan ottaa asteittain ka\u0308ytto\u0308o\u0308n verrattain lyhyella\u0308kin aikava\u0308lilla\u0308. Malli on karsittu versio Elina Valtosen (ent. Lepom\u00E4ki)", ' ', _jsx(A, __assign({ href: "https://www.libera.fi/wp-content/uploads/2013/12/Perustili_final1.pdf", target: "_blank" }, { children: "ehdottamasta" }), void 0), ' ', "kokonaisvaltaisesta sosiaaliturvan uudistuksesta."] }, void 0),
                    _jsx(P, { children: "Ehdottamallamme perustilimallilla tavoitellaan seuraavia hy\u00F6tyj\u00E4:" }, void 0),
                    _jsxs("ol", { children: [_jsxs(Li, { children: ["Perustili ", _jsx(Bold, { children: "parantaa ihmisten oman el\u00E4m\u00E4n suunnittelua" }, void 0), ". Perustilin nosto-oikeus perustuu automaattisiin tietoihin tulorekisterist\u00E4, joten seuraavan kuukauden tulot eiv\u00E4t ole riippuvaisia ennakoimattomasta byrokratiasta. Perustilin my\u00F6t\u00E4 ihmiset uskaltavat tehd\u00E4 t\u00F6it\u00E4 pelk\u00E4\u00E4m\u00E4tt\u00E4 esimerkiksi etuuksien takaisinperint\u00E4\u00E4."] }, void 0),
                            _jsxs(Li, { children: ["Perustili ", _jsx(Bold, { children: "lis\u00E4\u00E4 ihmisten kyky\u00E4 reagoida muutoksiin" }, void 0), " yh\u00E4 nopeatempoisemmassa maailmassa. Etuuksien oma hallinta yhdistettyn\u00E4 automatisointiin helpottaa reagoimista yll\u00E4tt\u00E4viin tapahtumiin el\u00E4m\u00E4nkulussa. Turvan saaminen ei edellyt\u00E4 hakemuksia, joiden k\u00E4sittelyajoista ei ole takuita. Turvan saa nopeasti ja v\u00E4litt\u00F6m\u00E4sti."] }, void 0),
                            _jsxs(Li, { children: ["Perustilin ansiosta", ' ', _jsx(Bold, { children: "ihmiset tekev\u00E4t t\u00F6it\u00E4 nykyist\u00E4 enemm\u00E4n" }, void 0), ". Perustili muuttaa sen, miten ihmiset kokevat ty\u00F6nteon kannusteet. Perustililt\u00E4 n\u00E4kee kuinka itse tehty ty\u00F6 kasvattaa henkil\u00F6kohtaisen tilin saldoa. Mit\u00E4 enemm\u00E4n ty\u00F6t\u00E4 tehd\u00E4\u00E4n, sit\u00E4 enemm\u00E4n saa vapautta \u2013 ei velvoittavaa toimintaa."] }, void 0),
                            _jsxs(Li, { children: ["Perustili saa ", _jsx(Bold, { children: "ihmiset opiskelemaan nykyist\u00E4 enemm\u00E4n" }, void 0), ". Malli kasvattaa opiskelun kiinnostavuutta, sill\u00E4 opintosuoritusten kautta on mahdollisuus saada k\u00E4ytt\u00F6oikeus perustiliin. Vaikutus opiskelujen kiinnostavuuteen voi olla merkitt\u00E4v\u00E4, koska perustili v\u00E4hent\u00E4\u00E4 byrokratiaa ja poistaa mm. takaisinperinn\u00E4n."] }, void 0),
                            _jsxs(Li, { children: [_jsx(Bold, { children: "Palvelut kohdentuvat osallistumistulon kautta nykyist\u00E4 paremmin" }, void 0), ". Osallistumistulon ymp\u00E4rille rakentuvat palvelut ovat aiempaa v\u00E4hemm\u00E4n pirstaleisia. Ohjaamopalvelut vastaavat ty\u00F6- ja uraohjauksesta ja tukipalvelut sosiaality\u00F6st\u00E4. T\u00E4ll\u00E4 on vaikutusta my\u00F6s palvelujen kustannusvaikuttavuuteen: julkiset varat on mahdollista k\u00E4ytt\u00E4\u00E4 nykyist\u00E4 tehokkaammin, kun kohderyhm\u00E4 valikoituu aiempaa pienemm\u00E4ksi."] }, void 0)] }, void 0),
                    _jsx(P, { children: "Perustili tarjoaisi siis vastauksia kolmeen sosiaaliturvakomitean m\u00E4\u00E4rittelemist\u00E4 nelj\u00E4st\u00E4 ongelmakohdasta: Ty\u00F6n ja sosiaaliturvan yhteensovittamiseen, sosiaaliturvan yksinkertaistamiseen ja palveluiden ja etuuksien yhteensovittamiseen." }, void 0),
                    _jsxs(P, { children: ["Keskeiset perustiliin liittyv\u00E4t selvitett\u00E4v\u00E4t asiat ovat", ' ', _jsx(Bold, { children: "olisiko perustili realistista ottaa k\u00E4ytt\u00F6\u00F6n" }, void 0), " ja", ' ', _jsx(Bold, { children: "pystyyk\u00F6 perustili vastaamaan yksil\u00F6n elinkaarellaan kohtaamiin haasteisiin nykyist\u00E4 sosiaaliturvaa joustavammin ja nopeammin" }, void 0), "."] }, void 0),
                    _jsx(ArticleSubtitle, { children: "Perustili ja osallistumistulo" }, void 0),
                    _jsx(P, { children: "Perustilimallissamme tyo\u0308markkinatuki, peruspa\u0308iva\u0308raha ja opintoraha korvataan perustililla\u0308 ja osallistumistulolla. Perustililt\u00E4 nuori voi vapaasti nostaa rahaa kuukausittain edellisen kuukauden ty\u00F6tulojen mukaan, kuitenkin enint\u00E4\u00E4n 650 \u20AC kuukaudessa. Perustili\u00E4 nuori ei kuitenkaan saa k\u00E4ytt\u00F6\u00F6n heti 18 vuotta t\u00E4ytetty\u00E4\u00E4n, vaan vasta opiskeltuaan tai oltuaan hetken ty\u00F6el\u00E4m\u00E4ss\u00E4, ja muutettuaan pois vanhempiensa luota." }, void 0),
                    _jsxs(UIExampleContainer, { children: [_jsx(UIExample, { src: uiExamplePic }, void 0),
                            _jsx(Caption, { children: "Esimerkki perustilin konkreettisesta k\u00E4yt\u00F6st\u00E4. Nostettava summa voidaan laskea tulorekisteriss\u00E4 n\u00E4kyv\u00E4st\u00E4 edellisen kuun palkasta." }, void 0)] }, void 0),
                    _jsx(P, { children: "Perustilin k\u00E4ytt\u00F6\u00F6nottoon asti, ja aina kun perustili menee nollille, nuori on osallistumistulon piiriss\u00E4. Osallistumistulon saamisen edellytyksen\u00E4 olisi yksil\u00F6llisesti laaditun tavoitteellisen suunnitelman toteuttaminen, oli se sitten el\u00E4m\u00E4nhallintaan liittyvien taitojen opettelua tai opiskelemaan l\u00E4htemist\u00E4. Osallistumistulo on siis vastikkeellista turvaa, ja perustili vastikkeeton tili jota saa k\u00E4ytt\u00E4\u00E4 tarpeen mukaan." }, void 0),
                    _jsx(P, { children: "Yksityiskohtaisemmin perustilimalli toimisi n\u00E4in:" }, void 0)] }, void 0),
            _jsxs(WideTextContent, { children: [_jsx(Figure, __assign({ sticky: true }, { children: _jsx(BaseAccountExplanation, { balance: baseAccountBalance }, void 0) }), void 0),
                    _jsxs(FigureTextContent, { children: [_jsx(TextBox, __assign({ className: "step", "data-step": "baseAccountExplanation-start" }, { children: _jsx(P, { children: "Perustili on 18 vuotta t\u00E4ytt\u00E4v\u00E4ll\u00E4 nuorella tyhj\u00E4. Nuori on osallistumistulon varassa kunnes nuori on tienannut 15 000 euroa ty\u00F6tuloja tai opiskellut aktiivisesti viisi kuukautta." }, void 0) }), void 0),
                            _jsx(TextBox, __assign({ className: "step", "data-step": "baseAccountExplanation-fill" }, { children: _jsx(P, { children: "Kynnyksen ehtojen t\u00E4ytytty\u00E4 nuori voi ottaa perustilin k\u00E4ytt\u00F6\u00F6n ja tilille lis\u00E4t\u00E4\u00E4n 20\u00A0000 \u20AC. Tililt\u00E4 voi nostaa korkeintaan 650 \u20AC kuukaudessa. Nostettavaan m\u00E4\u00E4r\u00E4\u00E4n vaikuttaa edellisen kuukauden ty\u00F6tulo: 300 euron j\u00E4lkeen jokainen ansaittu euro pienent\u00E4\u00E4 nostettavaa summaa puoli euroa." }, void 0) }), void 0),
                            _jsx(TextBox, __assign({ className: "step", "data-step": "baseAccountExplanation-use-1y" }, { children: _jsx(P, { children: "Jos nuori opiskelee vuodesta yhdeks\u00E4n kuukautta ja nostaa perustililt\u00E4 maksimim\u00E4\u00E4r\u00E4n, tilin saldo pienenee 5\u00A0850 euroa. Kolmen kuukauden kes\u00E4ty\u00F6 2\u00A0000 euron kuukausipalkalla puolestaan kasvattaa tilin saldoa 600 eurolla (10% ty\u00F6tuloista). Nuoren saamiin palkkatuloihin t\u00E4m\u00E4 ei kuitenkaan vaikuta." }, void 0) }), void 0),
                            _jsx(TextBox, __assign({ className: "step", "data-step": "baseAccountExplanation-empty" }, { children: _jsx(P, { children: "Jos opiskelija jatkaa opiskelua ilman osa-aikat\u00F6it\u00E4, perustili menee nollille ja sen k\u00E4ytt\u00F6oikeus loppuu. Nuori palaa takaisin osallistumistulon piiriin. T\u00E4ll\u00F6in nuoren on haettava tukia ja nuori ohjataan mahdollisesti tarvitsemiinsa palveluihin." }, void 0) }), void 0),
                            _jsx(TextBox, __assign({ className: "step", "data-step": "baseAccountExplanation-refill" }, { children: _jsx(P, { children: "Kun 15\u00A0000 euron ty\u00F6tulokynnys t\u00E4yttyy, perustilille ty\u00F6tuloista kertynyt summa (1\u00A0500 \u20AC) on j\u00E4lleen nostettavissa ja nuori siirtyy pois osallistumistulolta. Ty\u00F6el\u00E4m\u00E4ss\u00E4 pysyess\u00E4\u00E4n nuori kerrytt\u00E4\u00E4 perustili\u00E4 edelleen, kunnes se on taas t\u00E4ynn\u00E4." }, void 0) }), void 0)] }, void 0)] }, void 0),
            _jsxs(TextContent, { children: [_jsx(SectionTitle, { children: "Nuoruuden toteutuneet elinkaaret" }, void 0),
                    _jsxs(P, { children: ["THL:n", ' ', _jsx(A, __assign({ target: "_blank", href: "https://thl.fi/fi/tutkimus-ja-kehittaminen/tutkimukset-ja-hankkeet/kansallinen-syntymakohortti-1987" }, { children: "Kansallinen syntym\u00E4kohortti 1987 -tutkimusaineisto" }), void 0), ' ', "ker\u00E4\u00E4 yhteen k\u00E4yt\u00E4nn\u00F6ss\u00E4 kaiken saatavilla olevan rekisteritiedon Suomessa vuonna 1987 syntyneist\u00E4. Nyt kun t\u00E4m\u00E4 ik\u00E4luokka on yli kolmekymppinen, voimme tutkia my\u00F6s heid\u00E4n saamiaan tuloja aikuisuuden kynnykselt\u00E4 opiskelujen yli aina ty\u00F6el\u00E4m\u00E4\u00E4n asti."] }, void 0),
                    _jsx(P, { children: "Kohortti87 on paras aineisto tutkia perustilin toimivuutta, sill\u00E4 se on riitt\u00E4v\u00E4n tuore ollakseen vertailukelpoinen nykyhetken kanssa ja riitt\u00E4v\u00E4n pitk\u00E4 elinkaarien tarkasteluun." }, void 0)] }, void 0),
            _jsxs(WideTextContent, { children: [_jsxs(Figure, __assign({ sticky: true }, { children: [_jsx(GraphTitle, { children: "Tulot ja etuudet, koko ik\u00E4luokka" }, void 0),
                            summaryData ? (_jsx(ClusterSummaryGrid, { summaryData: summaryData, clusters: ['yhteensa'], frame: summaryChartFrame, hideTitles: true }, void 0)) : (_jsx(Spinner, {}, void 0))] }), void 0),
                    _jsxs(FigureTextContent, { children: [_jsxs(TextBox, __assign({ className: "step", "data-step": "lifespan-study" }, { children: [_jsx(P, { children: "Pohjaksi simulaatiolle tutkimme, miten keskim\u00E4\u00E4r\u00E4isen nuoren tulot ovat muodostuneet vuodesta 2005 eteenp\u00E4in aina vuoteen 2015, eli n. 18-vuotiaasta n. 28-vuotiaaksi." }, void 0),
                                    _jsx(P, { children: "Viivan alle ker\u00E4\u00E4mme saadut ty\u00F6tt\u00F6myys- ja opintoetuudet, ja viivan p\u00E4\u00E4lle ty\u00F6tulot." }, void 0),
                                    _jsxs(P, { children: [_jsx(Bold, { children: "Opintorahaa" }, void 0), " saadaan eniten n. 22\u201323-vuotiaana, ja tyypillisesti n. 9 kuukautta vuodessa."] }, void 0)] }), void 0),
                            _jsx(TextBox, __assign({ className: "step", "data-step": "lifespan-unemployment" }, { children: _jsxs(P, { children: ["Saadut ", _jsx(Bold, { children: "ty\u00F6markkinatuet" }, void 0), " kasvavat jonkin verran sen j\u00E4lkeen, kun ik\u00E4luokka valmistuu opinnoista. Ty\u00F6tt\u00F6myysetuuksia nostettiin tarkastelujakson lopussa n. 90 \u20AC kuukaudessa per henkil\u00F6."] }, void 0) }), void 0),
                            _jsx(TextBox, __assign({ className: "step", "data-step": "lifespan-pay" }, { children: _jsx(P, { children: "Ty\u00F6tulot nousevat nuorena ty\u00F6el\u00E4m\u00E4\u00E4n l\u00E4htevien johdolla nousuun jo aikaisessa vaiheessa. Opiskelijoiden kes\u00E4ty\u00F6t tuottavat kes\u00E4kuukausille kausittaisia piikkej\u00E4, jotka tasaantuvat valmistumisen my\u00F6t\u00E4. Keskikuukausipalkka nousee suurimmillaan 1\u00A0900 euroon." }, void 0) }), void 0),
                            _jsxs(TextBox, __assign({ className: "step", "data-step": "lifespan-cumulative" }, { children: [_jsx(P, { children: "Kuukausittaisen tilaston sijaan voimme tarkastella my\u00F6s kertymi\u00E4. Vuoden 2015 loppuun menness\u00E4 ik\u00E4luokka ehti saada ty\u00F6markkinatukia keskim\u00E4\u00E4rin n. 6\u00A0000 \u20AC ja opintorahaa n. 7\u00A0000 \u20AC, yhteens\u00E4 siis hieman yli 13\u00A0000\u00A0\u20AC. Ty\u00F6tuloja taas kertyi n. 140\u00A0000\u00A0\u20AC." }, void 0),
                                    _jsxs(P, { children: ["K\u00E4ytett\u00E4viss\u00E4 olevien tietojen perusteella voidaan arvioida, ett\u00E4 ik\u00E4luokka on maksanut tuloistaan v\u00E4hint\u00E4\u00E4n 10% veroja (14\u00A0000\u00A0\u20AC/hl\u00F6). Yhteens\u00E4 ik\u00E4luokalle kertyi siis etuuksia 777 miljoonaa ja verotuloja v\u00E4hint\u00E4\u00E4n 833 miljoona. Yksinkertaistaen", ' ', _jsx(Bold, { children: "vuonna 1987 syntyneet pystyiv\u00E4t siis maksamaan itse omat opintoetuutensa ja ty\u00F6markkinatukensa" }, void 0), "."] }, void 0)] }), void 0)] }, void 0)] }, void 0),
            _jsxs(TextContent, { children: [_jsx(ArticleSubtitle, { children: "Erilaiset elinkaaret tarvitsevat eriasteista tukea" }, void 0),
                    _jsxs(P, { children: ["Kohortti87-aineistoa on", ' ', _jsx(A, __assign({ href: "http://www.llcsjournal.org/index.php/llcs/article/view/441", target: "_blank" }, { children: "aiemmin jaettu" }), void 0), ' ', "sekvenssianalyysilla my\u00F6s ryhmiin elinkaarien samankaltaisuuden mukaan. My\u00F6s tulonmuodostusta voidaan tarkastella n\u00E4iden ryhmien perusteella. THL:n tarkastelussa ryhmi\u00E4 on kymmenen, t\u00E4ss\u00E4 artikkelissa tarkastelemme niist\u00E4 nelj\u00E4\u00E4."] }, void 0),
                    _jsxs(WideTextContent, { children: [_jsx(GraphTitle, { children: "Keskim\u00E4\u00E4r\u00E4isen nuoren tulot ja etuudet er\u00E4iss\u00E4 ryhmiss\u00E4" }, void 0),
                            summaryData ? (_jsx(ClusterSummaryGrid, { summaryData: summaryData, clusters: shownClusters, showAmounts: true }, void 0)) : (_jsx(Spinner, {}, void 0))] }, void 0),
                    _jsx(P, { children: "Suurimpina ryhmin\u00E4 erottuvat korkea-asteen koulutukseen hakeutuvat ja suoraan ammattikoulusta ty\u00F6el\u00E4m\u00E4\u00E4n p\u00E4\u00E4sev\u00E4t. N\u00E4m\u00E4 ryhm\u00E4t kattavat 73 % koko kohortista, ansaitsevat 87 % ty\u00F6tuloista ja k\u00E4ytt\u00E4v\u00E4t 67 % etuuksista. Na\u0308ihin ryhmiin kuuluvat nuorten elinkaaret ovat vakuuttamisen na\u0308ko\u0308kulmasta turvallisella polulla: Suurin osa nuorista saa kesta\u0308va\u0308n tyo\u0308uran la\u0308pi seuraavien vuosikymmenten joko korkea-asteen tutkinnon tai ty\u00F6kokemuksen ansiosta." }, void 0),
                    _jsx(P, { children: "Ryhma\u0308t \u201CTyo\u0308tto\u0308myys- ja toimeentulotuki\u201D ja l\u00E4hinn\u00E4 p\u00E4tk\u00E4t\u00F6it\u00E4 tehneet ryhm\u00E4t ovat selkea\u0308sti vaikeimmassa tilanteessa. Tyo\u0308tto\u0308myytta\u0308 on erityisesti nuoruuden elinkaaren lopussa enemma\u0308n kuin muilla ryhmilla\u0308. Na\u0308ma\u0308 nuoret muodostavat noin 6 200 henkilo\u0308n ryhma\u0308n, joka on kymmenesosa ika\u0308luokasta. Kyse on ryhma\u0308sta\u0308 joka ei syysta\u0308 tai toisesta ole lo\u0308yta\u0308nyt paikkaansa tyo\u0308uralla, joka turvaisi heille itsena\u0308isen toimeentulon." }, void 0),
                    _jsx(P, { children: "N\u00E4m\u00E4 ryhm\u00E4t ovat sosiaalipoliittisesta na\u0308ko\u0308kulmasta kokonaisuus, jonka koon pita\u0308isi pienentya\u0308. Sosiaaliturvan uudistaminen perustililla\u0308 ja osallistumistulolla tarjoaa ta\u0308ha\u0308n yhden ty\u00F6kalun." }, void 0),
                    _jsx(P, { children: "On t\u00E4rke\u00E4\u00E4 huomata, ett\u00E4 edell\u00E4mainituista ryhmist\u00E4 ainoastaan toisen asteen koulutuksen j\u00E4lkeen suoraan ty\u00F6el\u00E4m\u00E4\u00E4n suunnistavat ja siell\u00E4 pysyv\u00E4t v\u00E4ltt\u00E4v\u00E4t sosiaaliturvaj\u00E4rjestelm\u00E4n l\u00E4hes kokonaan. Kaikilla muilla ryhmill\u00E4 nuoruus on tanssia erilaisten tukien lomassa." }, void 0),
                    _jsx(SectionTitle, { children: "Simulaatio: Ent\u00E4 jos 1987-syntyneill\u00E4 olisi ollut perustili k\u00E4yt\u00F6ss\u00E4?" }, void 0),
                    _jsx(P, { children: "THL:n kohorttiaineisto mahdollistaa ns. kontrafaktuaalisen tarkastelun. Voimme laskennallisesti kirjoittaa historiaa uusiksi antamalla vuonna 1987 syntyneille perustilin ka\u0308ytto\u0308o\u0308n ika\u0308vuosien 18\u201228 ajaksi. Simuloinnilla haettiin vastauksia seuraaviin kysymyksiin:" }, void 0),
                    _jsxs("ul", { children: [_jsx(Li, { children: "Onko malli realistinen?" }, void 0),
                            _jsx(Li, { children: "Miten perustili toimisi eri ryhmill\u00E4?" }, void 0),
                            _jsx(Li, { children: "Mitk\u00E4 mallin kustannukset olisivat?" }, void 0)] }, void 0),
                    _jsx(P, { children: "Loimme aiemmin esitellyn mallin pohjalta simulaation, jonka THL ajoi Kohortti 87 -aineiston p\u00E4\u00E4lle ja toimitti meille aineistosta lasketut tunnusluvut (ns. aggregoidun aineiston). T\u00E4ss\u00E4 raportissa esitetyt luvut perustuvat t\u00E4h\u00E4n aggregoituun aineistoon." }, void 0),
                    _jsx(P, { children: "Perustilimallin dynaamisia vaikutuksia, eli sit\u00E4, kuinka hyvin osallistumistulo aktivoisi takaisin ty\u00F6el\u00E4m\u00E4\u00E4n tai koulutuksen pariin, ei viel\u00E4 t\u00E4ss\u00E4 simulaatiossa yritetty mallintaa." }, void 0),
                    _jsxs(FactBox, __assign({ collapsed: true }, { children: [_jsx(FactBoxTitle, { children: "Kuinka simulaatio tehtiin?" }, void 0),
                            _jsx(P, { children: "Simuloinnissamme hy\u00F6dynnettiin kohorttitutkimusta varten ker\u00E4ttyj\u00E4 tietoja opintorahasta, ty\u00F6markkinatuesta ja ty\u00F6tuloista (perustuen ty\u00F6el\u00E4kevakuutustietoihin). Aineistossa kaikki eurom\u00E4\u00E4r\u00E4iset luvut on esitetty vuoden 2016 rahassa. Edell\u00E4 mainittujen tietojen avulla kullekin henkil\u00F6lle summattiin kullekin kuukaudelle:" }, void 0),
                            _jsxs("ul", { children: [_jsx(Li, { children: "Maksetut opintorahat" }, void 0),
                                    _jsx(Li, { children: "Maksetut ty\u00F6markkinatuet ja perusp\u00E4iv\u00E4rahat" }, void 0),
                                    _jsx(Li, { children: "Ty\u00F6tulot (Palkansaajan ja yritt\u00E4j\u00E4n ty\u00F6tulojen koko vuoden summa jyvitettyn\u00E4 kuukausittain ty\u00F6el\u00E4kevakuutusjaksojen pituuden mukaan)" }, void 0)] }, void 0),
                            _jsx(P, { children: "Simuloinnissa on siis huomioitu vain n\u00E4m\u00E4 etuudet. N\u00E4iden avulla kullekin henkil\u00F6lle laskettiin kuukausittain perustilin saldo ja nostojen ja talletusten summa." }, void 0),
                            _jsx(P, { children: "Simuloinnissamme oli seuraavat periaatteet:" }, void 0),
                            _jsxs("ul", { children: [_jsx(Li, { children: "Tilin saldo ei voi olla negatiivinen eik\u00E4 ylitt\u00E4\u00E4 alkup\u00E4\u00E4omaa" }, void 0),
                                    _jsx(Li, { children: "Tilin perustamisen ja osallistumistulolta palaamiseen liittyy aiemmin mainitut kynnysarvot, 5kk opintoja tai 15\u00A0000 \u20AC tuloja" }, void 0),
                                    _jsx(Li, { children: "Nostoiksi perustililt\u00E4 laskettiin henkil\u00F6lle kunkin kuukauden aikana maksetut etuudet" }, void 0),
                                    _jsx(Li, { children: "Eli jos henkil\u00F6 oli saanut 600 euroa ty\u00F6markkinatukia tiettyn\u00E4 kuukautena, perustilin saldo v\u00E4heni 600 euroa simuloinnissa" }, void 0),
                                    _jsx(Li, { children: "Nostettu opintoraha on korvattu maksiminostolla. Eli jos henkil\u00F6 oli nostanut opintorahaa, tulkittiin, ett\u00E4 sit\u00E4 on nostettu 650 euroa. T\u00E4m\u00E4n tarkoitus on v\u00E4hent\u00E4\u00E4 yleisen asumistuen tarvetta." }, void 0),
                                    _jsx(Li, { children: "Talletukset perustilille perustuvat toteutuneisiin ty\u00F6tuloihin. Talletus oli 10% kunkin kuukauden ty\u00F6tuloista. Eli jos henkil\u00F6 oli tienannut 2000 euroa, perustilille talletettiin simuloinnissa 200 euroa" }, void 0)] }, void 0),
                            _jsx(P, { children: "T\u00E4m\u00E4n lis\u00E4ksi teimme toisen simuloinnin hieman eri periaattein, jossa perustililt\u00E4 on nostettu maksimim\u00E4\u00E4r\u00E4 aina kun se on mahdollista. Vertailemalla eri simulointeja saadaan k\u00E4sitys siit\u00E4, ett\u00E4 mihin kustannukset voisivat asettua, mik\u00E4li perustilin k\u00E4ytt\u00F6\u00F6n siirrytt\u00E4isiin. Kyse ei siis ole eri perustilimallien simuloinnista, vaan yhden mallin kustannusten haarukoinnista." }, void 0),
                            _jsx(P, { children: "Mallin tulokset aggregoitiin erikseen my\u00F6s aiemmin esitellyn THL:n tuottaman ryhmittelyn mukaan. N\u00E4in n\u00E4emme, miten malli k\u00E4ytt\u00E4ytyisi erilaisilla el\u00E4m\u00E4nkaarilla." }, void 0)] }), void 0)] }, void 0),
            _jsxs(WideTextContent, { children: [_jsxs(Figure, __assign({ sticky: true }, { children: [_jsx(GraphTitle, { children: "Tilin k\u00E4ytt\u00F6 koko ik\u00E4luokalla" }, void 0),
                            _jsx(Legend, { children: ['tili_taynna', 'tili_kaytossa', 'tili_nollilla'].map(function (key) { return (_jsx(LegendItem, { color: theme.getFill(key), text: texts[key] }, key)); }) }, void 0),
                            (articleData === null || articleData === void 0 ? void 0 : articleData.baseAccountData) ? (_jsx(ClusterAccountUseGraph, { clusterData: articleData === null || articleData === void 0 ? void 0 : articleData.baseAccountData['yhteensa'], title: '', lastShownIndex: useChartState.lastShownIndex, shownSeries: useChartState.shownSeries }, void 0)) : (_jsx(Spinner, {}, void 0))] }), void 0),
                    _jsxs(FigureTextContent, { children: [_jsx(TextBox, __assign({ className: "step", "data-step": "simulation-example-empty" }, { children: _jsx(P, { children: "Tarkastellaan ensin osallistumistulon k\u00E4ytt\u00F6\u00E4. Alkuvaiheessa kaikki ovat osallistumistulon varassa, koska kynnysehdot eiv\u00E4t ole ehtineet t\u00E4ytty\u00E4. Ensimm\u00E4isen vuoden aikana tili aukeaa n. kolmasosalle ik\u00E4luokasta." }, void 0) }), void 0),
                            _jsx(TextBox, __assign({ className: "step", "data-step": "simulation-example-empty-2" }, { children: _jsx(P, { children: "23 ik\u00E4vuoteen menness\u00E4 tilin k\u00E4ytt\u00F6 saavuttaa huippunsa, eli kynnysehdot ovat suurimmalla osalla t\u00E4yttyneet, mutta tili ei ole viel\u00E4 kovin monella tyhj\u00E4n\u00E4 k\u00E4yt\u00F6n vuoksi." }, void 0) }), void 0),
                            _jsx(TextBox, __assign({ className: "step", "data-step": "simulation-example-empty-3" }, { children: _jsx(P, { children: "Pidemm\u00E4ll\u00E4 tarkastelujaksolla osallistumistulolla olevien m\u00E4\u00E4r\u00E4 asettuu noin 10 000 henkil\u00F6\u00F6n, joka on n. 16 prosenttia ik\u00E4luokasta." }, void 0) }), void 0),
                            _jsx(TextBox, __assign({ className: "step", "data-step": "simulation-example-account-full" }, { children: _jsx(P, { children: "Simuloinnin p\u00E4\u00E4ttyess\u00E4 tili oli t\u00E4ynn\u00E4 l\u00E4hes 18\u00A0000 henkil\u00F6ll\u00E4 eli 30 prosentilla ik\u00E4luokasta." }, void 0) }), void 0),
                            _jsxs(TextBox, __assign({ className: "step", "data-step": "simulation-example-full-picture" }, { children: [_jsx(P, { children: "Kokonaisuutta tarkastellessa malli na\u0308ytta\u0308a\u0308 toimivalta, silla\u0308 syrja\u0308ytymisuhan alla olevat ohjautuvat osallistumispalveluiden piiriin ja kesta\u0308va\u0308lla\u0308 tyo\u0308uralle oleviin kohdistetaan palveluiden sijaan kannusteita." }, void 0),
                                    _jsx(P, { children: "Perustili on ka\u0308yto\u0308ssa\u0308 valtaosalla nuorista 22 ika\u0308vuodesta eteenpa\u0308in. Ta\u0308ma\u0308 on kannusteiden na\u0308ko\u0308kulmasta erinomainen asia \u2013 perustilin \u201Dmita\u0308 enemma\u0308n to\u0308ita\u0308, sita\u0308 enemma\u0308n vapautta\u201D-ominaisuus on relevantti erityisesti ta\u0308ssa\u0308 ela\u0308ma\u0308nvaiheessa." }, void 0)] }), void 0)] }, void 0)] }, void 0),
            _jsx(TextContent, { children: _jsx(P, { children: "Aiemmin esitellyn ryhmittelyn pohjalta simulaatio k\u00E4ytt\u00E4ytyy odotetusti: Yli puolet niist\u00E4, jotka ovat pitkittyneesti ty\u00F6tt\u00F6myys- ja toimeentulotuen varassa ovat osallistumistulon piiriss\u00E4. Suuri osa ik\u00E4luokasta, eli toisen asteen tutkinnon j\u00E4lkeen ty\u00F6llistyneet ja korkea-asteen koulutuksen saaneet taas pysyv\u00E4t pitk\u00E4lti perustilin varassa ja korkeakoulutetuilla alkaa pikkuhiljaa tili t\u00E4ytty\u00E4 30 ik\u00E4vuoteen menness\u00E4." }, void 0) }, void 0),
            _jsxs(WideTextContent, { children: [_jsx(GraphTitle, { children: "Perustilin k\u00E4ytt\u00F6 eri ryhmiss\u00E4" }, void 0),
                    (articleData === null || articleData === void 0 ? void 0 : articleData.baseAccountData) ? (_jsx(ClusterAccountUseGrid, { clusterData: articleData === null || articleData === void 0 ? void 0 : articleData.baseAccountData, clusters: shownClusters }, void 0)) : undefined] }, void 0),
            _jsxs(TextContent, { children: [_jsx(ArticleSubtitle, { children: "Perustili olisi realistinen sosiaaliturvan j\u00E4rjest\u00E4misen tapa" }, void 0),
                    _jsx(P, { children: "Simulaatio osoittaa, ett\u00E4 perustili olisi realistinen tapa j\u00E4rjest\u00E4\u00E4 sosiaaliturva." }, void 0),
                    _jsx(P, { children: "Suurin osa ik\u00E4luokasta p\u00E4rj\u00E4isi perustilin varassa. Noin 16% ik\u00E4luokasta saa osallistumistuloa pitk\u00E4ll\u00E4 aikav\u00E4lill\u00E4. T\u00E4m\u00E4 tekisi sosiaalipolitiikan kohdentamisesta nykyist\u00E4 helpompaa ja tehokkaampaa. Ryhm\u00E4, joka tarvitsisi eniten tukea, tunnistettaisiin." }, void 0),
                    _jsx(P, { children: "Jos perustililt\u00E4 nostettaisiin maksimisumma joka kerta kun se on mahdollista, kustannukset kasvaisivat suhteessa ty\u00F6markkinatuen, perusp\u00E4iv\u00E4rahan ja opintorahan nykytasoon. Simulaatiossa ei pystyt\u00E4 kuitenkaan huomioimaan sit\u00E4, miss\u00E4 nuoret asuvat. Koska perustilin k\u00E4ytt\u00F6oikeuden ehtona on \u201Cmuutto vanhempien luota\u201D , eiv\u00E4t simulaation luvut t\u00E4ysi-ik\u00E4isyyden t\u00E4ytt\u00E4vien osalta ole todenn\u00E4k\u00F6isi\u00E4. Vuoden 2014 lopussa 20\u201324-vuotiaista asui kotona 25 prosenttia (Tilastokeskus 2015). Yli 25-vuotiaiden osalta perustilin maksiminostojen ja toteutuneen etuuskulutuksen (ty\u00F6markkinatuki, perusp\u00E4iv\u00E4raha, opintoraha) erotus voi olla 0 - 50 miljoonan luokkaa." }, void 0),
                    _jsx(P, { children: "Kun huomioidaan my\u00F6s muut kuin korvattavat etuudet, niin arviomme mukaan perustilimalli ei k\u00E4yt\u00E4nn\u00F6ss\u00E4 lis\u00E4isi yksil\u00F6iden k\u00E4ytett\u00E4viss\u00E4 olevien eurojen m\u00E4\u00E4r\u00E4\u00E4, eik\u00E4 siten julkisia kustannuksiakaan. Sen sijaan perustilin my\u00F6t\u00E4 etuuksien autonominen k\u00E4ytt\u00F6 kasvaisi ja tarveharkintaisten etuuksien painoarvo v\u00E4henisi. Toimeentulotuesta ja asumistuesta tulisi nykyist\u00E4 enemm\u00E4n perusturvaa paikkaavia etuuksia. Juuri niin kun niiden on tarkoitettukin olevan." }, void 0),
                    _jsx(SectionTitle, { children: "Perustili ja osallistumistulo loisivat yhdess\u00E4 kest\u00E4vi\u00E4 ty\u00F6uria" }, void 0),
                    _jsx(P, { children: "Kun nuori ei tied\u00E4, miten lyhyen ty\u00F6keikan tekeminen vaikuttaa tuloihin ja etujen maksuaikatauluihin, on varminta olla tekem\u00E4tt\u00E4 mit\u00E4\u00E4n." }, void 0),
                    _jsx(P, { children: "Perustilill\u00E4 olisi potentiaalia vaikuttaa t\u00E4h\u00E4n. On todenn\u00E4k\u00F6ist\u00E4, ett\u00E4 etuuksien hallinta yhdistettyn\u00E4 automatisointiin vaikuttaisi siihen, ett\u00E4 yksil\u00F6t kokisivat sosiaaliturvan nykyist\u00E4 selke\u00E4mp\u00E4n\u00E4. Yll\u00E4tt\u00E4viin riskeihin reagoimisesta tulisi vaivattomampaa, kun etuuden nostaminen olisi omissa k\u00E4siss\u00E4. Turvan saaminen ei edellytt\u00E4isi hakemuksia. On mielenkiintoista pohtia miten t\u00E4m\u00E4 vaikuttaisi ty\u00F6llisyyteen \u2013 dynaamisia vaikutuksia ei on mahdotonta etuk\u00E4teen tiet\u00E4\u00E4, mutta ep\u00E4ilem\u00E4tt\u00E4 potentiaalia olisi." }, void 0),
                    _jsx(SectionTitle, { children: "Perustilimallin k\u00E4ytt\u00F6\u00F6notto" }, void 0),
                    _jsx(P, { children: "Tilkkuta\u0308kkima\u0308isen sosiaaliturvan korjaaminen pala kerrallaan ei johda tavoitteiden saavuttamiseen, vaan voi pahimmillaan jopa pahentaa ongelmia. Sosiaaliturvakomitean asettamien tavoitteiden saavuttaminen edellytta\u0308a\u0308 valintaa \u2012 mieluiten jo la\u0308hivuosina \u2012 \u201Dalustasta\u201D, jonka pa\u0308a\u0308lle uusi sosiaaliturva rakennetaan. Perustili yhdistettyna\u0308 sosiaaliturvaan tarjoaa ta\u0308ha\u0308n luontevan vaihtoehdon. Teknologia ja rekisterien (erityisesti tulorekisterin) kehitys mahdollistavat teknisen toteutuksen. Seuraavaksi tarvitaan poliittiset pa\u0308a\u0308to\u0308kset." }, void 0),
                    _jsx(P, { children: "Perustilia\u0308 ja osallistumistuloa koskeva pa\u0308a\u0308to\u0308ksenteko on monivaiheinen prosessi. Aluksi voidaan toteuttaa toimenpiteet, jotka joka tapauksessa on pakko tehda\u0308. Myo\u0308hemmin \u2013 mahdollisten jatkoselvitysten pohjalta \u2013 voidaan linjata perustilin ja osallistumistulon ka\u0308ytto\u0308o\u0308nottoa. Luonteva paikka ta\u0308lle olisi seuraavat hallitusneuvottelut. Alla on lueteltu muutokset, joista ainakin tulisi pa\u0308a\u0308tta\u0308a\u0308." }, void 0),
                    _jsx(GraphTitle, { children: "Kelaan tehta\u0308va\u0308t muutokset:" }, void 0),
                    _jsxs("ol", { children: [_jsxs(Li, { children: [_jsx(Bold, { children: "Etuuspa\u0308a\u0308to\u0308sten automatisointi." }, void 0), " Etuuksien toimeenpanoon liittyvia\u0308 tehta\u0308via\u0308 ja toimenpiteita\u0308 on automatisoitu 2000-luvulla kohtuullisen paljon. Seuraava askel on tuoda tulorekisterin, opintorekisterin ja muiden rekisterien tiedot nykyista\u0308 tehokkaammin tyo\u0308tto\u0308myys- ja opintoetuuksia koskevaa pa\u0308a\u0308to\u0308ksentekoon."] }, void 0),
                            _jsxs(Li, { children: [_jsx(Bold, { children: "\u201DOikeuteni etuuksiin\u201D-ka\u0308ytto\u0308liittyma\u0308n koodaaminen Kelan asiointipalveluun." }, void 0), ' ', "Kela on kehitta\u0308nyt asiointipalvelun, josta voi tarkastella itsea\u0308a\u0308n koskevia pa\u0308a\u0308to\u0308ksia\u0308 ja tehda\u0308 hakemuksia. Seuraava askel on kehitta\u0308a\u0308 tulorekisteria\u0308 ja muita rekistereita\u0308 lukeva ka\u0308ytto\u0308liittyma\u0308, josta kansalainen voi na\u0308hda\u0308 ja ennen kaikkea ennakoida itsea\u0308a\u0308n koskevia pa\u0308a\u0308to\u0308ksia\u0308."] }, void 0)] }, void 0),
                    _jsx(GraphTitle, { children: "Palveluja\u0308rjestelma\u0308a\u0308n tehta\u0308va\u0308t muutokset:" }, void 0),
                    _jsxs("ol", __assign({ start: 3 }, { children: [_jsxs(Li, { children: [_jsx(Bold, { children: "Ohjaamopalveluiden painopiste ja resursointi." }, void 0), " Marinin hallitus on linjannut, etta\u0308 tyo\u0308llisyyspalvelut siirreta\u0308a\u0308n kuntien vastuulle vuonna 2024. Osana ta\u0308ta\u0308 pa\u0308a\u0308to\u0308sta\u0308 ohjaamopalvelut tulee siirta\u0308a\u0308 kuntien ja\u0308rjesta\u0308misvastuulle ja kasvattaa palveluiden painoarvoa valtionosuusja\u0308rjestelma\u0308n kautta. Ohjaamot tulee irrottaa nykyisista\u0308 sanktioja\u0308rjestelma\u0308sta\u0308 (TE-toimisto) ja varmistaa, etta\u0308 niiden toiminnan pa\u0308a\u0308periaate \u2013 vapaaehtoisuus \u2013 sa\u0308ilyy. Ohjaamoiden tulee koordinoida tyo\u0308- ja uraohjausta seka\u0308 kotouttamista. Muut tehta\u0308va\u0308t hoitaa tukipalvelu."] }, void 0),
                            _jsxs(Li, { children: [_jsx(Bold, { children: "Tukipalvelut." }, void 0), " Ihmisten osallisuutta tukemaan perustetaan kuntiin tukipalvelut. Niiden roolina on koordinoida sosiaali- ja terveydenhuoltoon liittyvia\u0308 palveluita. Tukipalvelut ylla\u0308pita\u0308va\u0308t auktorisoitua palvelurekisteria\u0308, josta valikoituu tukea tarvitsevalle tyo\u0308llistymistodenna\u0308ko\u0308isyyksia\u0308 kasvattava palvelu. Auktorisoituihin palveluihin osallistuminen riitta\u0308a\u0308 tarveharkintaisten etuuksien osalta aktiivisuuden osoittamiseen."] }, void 0)] }), void 0),
                    _jsx(GraphTitle, { children: "Etuuslainsa\u0308a\u0308da\u0308nto\u0308o\u0308n tehta\u0308va\u0308t muutokset:" }, void 0),
                    _jsx("ol", __assign({ start: 5 }, { children: _jsxs(Li, { children: [_jsx(Bold, { children: "Tyo\u0308markkinatuen, peruspa\u0308iva\u0308rahan ja opintotukea koskevan etuuslainsa\u0308a\u0308da\u0308nno\u0308n yhdista\u0308minen." }, void 0), ' ', "Toimenpiteiden 1\u20124 ja\u0308lkeen on edellytykset ottaa perustili ja osallistumistulo ka\u0308ytto\u0308o\u0308n. Lainsa\u0308a\u0308da\u0308nto\u0308o\u0308n tehta\u0308va\u0308t muutokset on todettu raportin aiemmissa luvuissa."] }, void 0) }), void 0),
                    _jsx(P, { children: "Ka\u0308ytto\u0308o\u0308noton yhteydessa\u0308 tulee ratkaista mille ika\u0308luokille perustili otetaan ka\u0308ytto\u0308o\u0308n. Kuvaamamme malli la\u0308htee siita\u0308, etta\u0308 se otetaan ka\u0308ytto\u0308o\u0308n 18. ika\u0308vuodesta eteenpa\u0308in \u2012 yksi ika\u0308luokka kerrallaan." }, void 0),
                    _jsx(P, { children: "K\u00E4ytt\u00F6\u00F6nottoon liittyv\u00E4t yhdenvertaisuuskysymykset on selvitetta\u0308va\u0308 oikeudellisesti ennen ka\u0308ytto\u0308o\u0308nottoa. Samoin on linjattava, miten perustilin saldo elinkaaren lopussa huomioidaan esim. osana ela\u0308keja\u0308rjestelma\u0308a\u0308." }, void 0),
                    _jsx(P, { children: "Esitta\u0308ma\u0308ssa\u0308mme laajuudessa perustilista\u0308 ja osallistumistulosta ei kannata tehda\u0308 mittavaa kokeilua. Elinkaarimallien vaikuttavuus syntyy elinkaaren aikana, eika\u0308 ta\u0308ten esimerkiksi kolmen tai edes viiden vuoden kokeilusta olisi hyo\u0308tya\u0308. Toki jotain perustilin tai osallistumistulon osaa voi mahdollisuuksien mukaan kokeilla, jos asetelma tuottaa kohtuullisessa ajassa luotettavaa tietoa vaikuttavuudesta." }, void 0),
                    _jsx(P, { children: "Lopuksi korostamme viela\u0308 kertaalleen, etta\u0308 esitta\u0308ma\u0308mme redusoitu perustilimalli on nimensa\u0308 mukaisesti karsittu versio perustilista\u0308. Se ei sisa\u0308lla\u0308 kaikkia erilaisia perustileihin liittyvia\u0308 ominaisuuksia (mm. yritta\u0308jyyden, tyo\u0308n teetta\u0308misen helpottaminen) tai tuo vastauksia kaikkiin sosiaaliturvan ongelmiin (kuten toimeentulotuki ja asumistuki), mutta luo pohjan myo\u0308hemma\u0308lle kehitta\u0308miselle. Sosiaaliturva tarvitsee uuden rakenteen (alustan), jonka pa\u0308a\u0308lle voidaan tulevaisuuden ratkaisut rakentaa." }, void 0),
                    _jsx(ShareButton, {}, void 0),
                    _jsxs(P, { children: ["Ty\u00F6ryhm\u00E4:", ' ', _jsx(Italic, { children: _jsx(A, __assign({ href: "mailto:jussi.pyykkonen@ptcs.fi" }, { children: "Jussi Pyykk\u00F6nen" }), void 0) }, void 0), ' ', "(Analyysi, p\u00E4\u00E4vastuu kirjoittamisesta) ,", ' ', _jsx(Italic, { children: "Topias Pyykko\u0308nen" }, void 0), " (Simulaatio, analyysi, tekstit) ja", ' ', _jsx(Italic, { children: _jsx(A, __assign({ href: "mailto:pekka.pulli@gmail.com" }, { children: "Pekka Pulli" }), void 0) }, void 0), ' ', "(Visualisaatiot, verkkosivu, tekstit)"] }, void 0),
                    _jsxs(FactBox, { children: [_jsx(FactBoxTitle, { children: "Lis\u00E4tietoja sivustosta" }, void 0),
                            _jsxs(P, { children: ["T\u00E4m\u00E4 artikkeli on tiivistelm\u00E4 laajemmasta raportista", ' ', _jsx(Italic, { children: "Perustili \u2013 tie kannustavaan ja la\u0308pina\u0308kyva\u0308a\u0308n sosiaaliturvaan" }, void 0), ". Lataa raportti", ' ', _jsx(A, __assign({ href: downloadURL, target: "_blank" }, { children: _jsx(Bold, { children: "t\u00E4st\u00E4" }, void 0) }), void 0), "."] }, void 0),
                            _jsx(P, { children: "Raportti ja verkkoartikkeli ovat osa teollisuuden automaatiota ja murrosta luotaavaa Manufacturing 4.0 -hanketta. Yksi hankkeen teemoista on Helsingin yliopiston ja professori Heikki Hiilamon johtama \u201DSosiaalipolitiikan vastaukset\u201D, johon t\u00E4m\u00E4 raportti sis\u00E4ltyy. Hanke saa rahoituksensa strategisen tutkimuksen neuvostolta ja on osa Sopeutuminen kest\u00E4v\u00E4n kasvun edellytyksen\u00E4 (ADAPT) -ohjelmaa." }, void 0),
                            _jsx(P, { children: "Kiita\u0308mme Strategisen tutkimuksen neuvostoa ja MFG 4.0 -hanketta mahdollisuudesta toteuttaa selvitys seka\u0308 THL:n professori Heikki Hiilamoa ideoinnista ja ohjauksesta. Aineiston osalta kiita\u0308mme yhteistyo\u0308sta\u0308 erityisesti THL:n tutkijaa Markus Keski-Sa\u0308nttia\u0308. Itsena\u0308isyyden juhlavuoden lastensa\u0308a\u0308tio\u0308ta\u0308 (Itla) kiita\u0308mme siita\u0308, etta\u0308 se on ottanut raporttimme sarjaansa. Lisa\u0308ksi kiita\u0308mme prosessin aikana saaduista kommenteista Olli Ka\u0308rkka\u0308ista\u0308, Mauri Kotama\u0308kea\u0308, Markus Lahtista, Pasi Moisiota, Osmo Soininvaaraa, Elina Valtosta, Juhana Vartiaista seka\u0308 kuulemiamme THL:n ja MFG 4.0 asiantuntijoita." }, void 0)] }, void 0)] }, void 0)] }, void 0));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
