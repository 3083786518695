var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import styled from 'styled-components';
import { AppContext } from '../../context';
import { theme } from '../../theme';
import { allClusters } from '../../types';
import { Grid, Legend, LegendItem } from '../common-styled-components';
import ClusterBalanceGraph from './ClusterBalanceGraph';
var GraphWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var ClusterAccountBalanceGrid = function (_a) {
    var clusterData = _a.clusterData;
    var selectedClusters = useContext(AppContext).selections.selectedClusters;
    return (_jsxs("div", { children: [_jsx(Legend, { children: _jsx(LegendItem, { color: theme.colors.blue, text: 'Perustilin mediaanisaldo' }, void 0) }, void 0),
            _jsx(Grid, { children: selectedClusters
                    .sort(function (c1, c2) {
                    return allClusters.indexOf(c1) - allClusters.indexOf(c2);
                })
                    .map(function (cluster) { return (_jsx(GraphWrapper, { children: _jsx(ClusterBalanceGraph, { cluster: cluster, baseData: clusterData, percentile: "P50" }, void 0) }, cluster)); }) }, void 0)] }, void 0));
};
var templateObject_1;
