var ratio = 1.25;
var baseFontSize = 16;
var fontSizeNumber = function (step) {
    var size = baseFontSize * Math.pow(ratio, step);
    return size;
};
var fontSize = function (step) {
    var size = fontSizeNumber(step);
    return "font-size: " + size + "px";
};
var spacingRatio = 1.618;
var spacingBase = 4;
var spacingNumber = function (step) {
    return spacingBase * Math.pow(spacingRatio, step);
};
var spacing = function (step) {
    return spacingNumber(step) + "px";
};
var greys = ['#191919', '#444', '#888', '#ccc', '#eee', '#f4f4f4'];
var grey = function (step) {
    return step < 0 ? 'black' : step >= greys.length ? 'white' : greys[step];
};
export var colors = {
    black: 'black',
    white: 'white',
    grey: grey,
    blackText: '#333333',
    blue: '#152660',
    purple: '#42355d',
    lightBlue: '#b3d9dd',
    yellow: '#f6e154',
    red: '#db948e',
    midGrey: '#7c7f85',
    lightGrey: '#dde4ed',
};
export var getFill = function (key) {
    switch (key) {
        case 'tili_nollilla':
            return colors.red;
        case 'opintoraha':
        case 'opintoraha_kumulatiivinen':
        case 'opintoraha_ka':
        case 'opintoraha_ka_kumulatiivinen':
        case 'opiskelija':
            return colors.lightGrey;
        case 'tyottomyysetuus':
        case 'tyottomyysetuus_kumulatiivinen':
        case 'tyottomyysetuus_ka':
        case 'tyottomyysetuus_ka_kumulatiivinen':
        case 'tili_kaytossa':
        case 'tyoton':
            return colors.yellow;
        case 'vanhempainetuudet':
        case 'vanhempainetuudet_ka':
            return colors.midGrey;
        case 'tyotulot_yhteensa':
        case 'tyotulot_yhteensa_kumulatiivinen':
        case 'tyotulot_yhteensa_ka':
        case 'tyotulot_yhteensa_ka_kumulatiivinen':
        case 'tyollinen':
        case 'tili_taynna':
            return colors.blue;
        case 'perustilinostoOP_rajoitettu_kynnys':
        case 'etuusnostoOpintorahaKorotettu_kynnys':
            return colors.blue;
        default:
            return colors.midGrey;
    }
};
export var theme = {
    fontTitle: "\n    font-family: \"Source Serif Pro\", serif;\n    font-style: normal;\n    font-weight: bold;\n  ",
    fontBold: "\n    font-family: \"Poppins\", sans-serif;\n    font-style: normal;\n    font-weight: bold;\n  ",
    fontNormal: "\n    font-family: \"Poppins\", sans-serif;\n    font-style: normal;\n    font-weight: normal\n  ",
    fontLabel: "\n    font-family: \"Poppins\", sans-serif;\n    font-style: normal;\n    font-weight: normal\n  ",
    fontLabelBold: "\n    font-family: \"Poppins\", sans-serif;\n    font-style: normal;\n    font-weight: bold\n  ",
    spacing: spacing,
    spacingNumber: spacingNumber,
    fontSize: fontSize,
    fontSizeNumber: fontSizeNumber,
    colors: colors,
    getFill: getFill,
};
export var breakpoints = {
    mobile: 480,
    tablet: 800,
    desktop: 1220,
};
