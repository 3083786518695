var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { dataSetNames, limitations, thresholds, } from '../types';
import { chain, cloneDeep, isNumber, mapValues, values } from 'lodash';
import { dsvFormat } from 'd3-dsv';
var sortByDate = function (d1, d2) {
    return d1.kuukausi.localeCompare(d2.kuukausi);
};
export var resolveBaseAccountData = function (data) {
    return chain(data)
        .groupBy(function (d) { return d.klusteri; })
        .mapValues(function (cluster) { return cluster.sort(sortByDate); })
        .valueOf();
};
export var getMonthlyClusterData = function (data) {
    return chain(data)
        .groupBy(function (d) { return d.kuukausi; })
        .mapValues(function (monthData) {
        return chain(monthData)
            .groupBy(function (d) { return d.klusteri; })
            .mapValues(function (d) { return d[0]; })
            .valueOf();
    })
        .valueOf();
};
export var getClusterSizes = function (monthlyData) {
    var monthData = monthlyData && values(monthlyData);
    return mapValues(monthData[0], function (cluster) { return cluster.henkiloita; });
};
export var fetchSummaryData = function (updateSummaryData) { return __awaiter(void 0, void 0, void 0, function () {
    var response, text, parsed, enrichedSummaryData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetch('/data/taulukko2_summat_suojattu.csv')];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.text()];
            case 2:
                text = _a.sent();
                parsed = dsvFormat(';').parse(text, function (row) {
                    return Object.keys(row).reduce(function (result, key) {
                        var _a;
                        var value = row[key];
                        var numberValue = Number(value);
                        return __assign(__assign({}, result), (_a = {}, _a[key] = isNumber(numberValue) && !isNaN(numberValue) ? numberValue : value, _a));
                    }, {});
                });
                enrichedSummaryData = enrichSummaryData(parsed);
                updateSummaryData(enrichedSummaryData);
                return [2 /*return*/];
        }
    });
}); };
export var enrichSummaryData = function (sums) {
    var summaryData = chain(sums)
        .groupBy(function (row) { return row.klusteri; })
        .mapValues(function (clusterData) {
        var tyottomyysetuus_kumulatiivinen = 0;
        var tyottomyysetuus_ka_kumulatiivinen = 0;
        var opintoraha_kumulatiivinen = 0;
        var opintoraha_ka_kumulatiivinen = 0;
        var etuudet_yhteensa_kumulatiivinen = 0;
        var etuudet_yhteensa_ka_kumulatiivinen = 0;
        var tyotulot_yhteensa_kumulatiivinen = 0;
        var tyotulot_yhteensa_ka_kumulatiivinen = 0;
        return clusterData.map(function (datum) {
            tyottomyysetuus_kumulatiivinen += datum.tyottomyysetuus;
            tyottomyysetuus_ka_kumulatiivinen +=
                datum.tyottomyysetuus / datum.henkiloita;
            opintoraha_kumulatiivinen += datum.opintoraha;
            opintoraha_ka_kumulatiivinen += datum.opintoraha / datum.henkiloita;
            var etuudet_yhteensa = datum.opintoraha + datum.tyottomyysetuus;
            var etuudet_yhteensa_ka = etuudet_yhteensa / datum.henkiloita;
            etuudet_yhteensa_kumulatiivinen += etuudet_yhteensa;
            etuudet_yhteensa_ka_kumulatiivinen += etuudet_yhteensa_ka;
            var tyotulot_yhteensa = datum.tyotulot + datum.tyotulot_yrittaja;
            var tyotulot_yhteensa_ka = tyotulot_yhteensa / datum.henkiloita;
            tyotulot_yhteensa_kumulatiivinen += tyotulot_yhteensa;
            tyotulot_yhteensa_ka_kumulatiivinen += tyotulot_yhteensa_ka;
            return __assign(__assign({}, datum), { opintoraha_ka: datum.opintoraha / datum.henkiloita, opintoraha_kumulatiivinen: opintoraha_kumulatiivinen,
                opintoraha_ka_kumulatiivinen: opintoraha_ka_kumulatiivinen, tyottomyysetuus_ka: datum.tyottomyysetuus / datum.henkiloita, tyottomyysetuus_kumulatiivinen: tyottomyysetuus_kumulatiivinen,
                tyottomyysetuus_ka_kumulatiivinen: tyottomyysetuus_ka_kumulatiivinen,
                etuudet_yhteensa: etuudet_yhteensa,
                etuudet_yhteensa_ka: etuudet_yhteensa_ka,
                etuudet_yhteensa_kumulatiivinen: etuudet_yhteensa_kumulatiivinen,
                etuudet_yhteensa_ka_kumulatiivinen: etuudet_yhteensa_ka_kumulatiivinen, vanhempainetuudet: datum.vanhempain_isyys_aitiys_paivaraha + datum.kotihoidontuki, vanhempainetuudet_ka: (datum.vanhempain_isyys_aitiys_paivaraha + datum.kotihoidontuki) /
                    datum.henkiloita, tyotulot_yhteensa: tyotulot_yhteensa,
                tyotulot_yhteensa_ka: tyotulot_yhteensa_ka,
                tyotulot_yhteensa_kumulatiivinen: tyotulot_yhteensa_kumulatiivinen,
                tyotulot_yhteensa_ka_kumulatiivinen: tyotulot_yhteensa_ka_kumulatiivinen, kaikki_tulot: datum.tyotulot +
                    datum.tyotulot_yrittaja +
                    datum.opintoraha +
                    datum.vanhempain_isyys_aitiys_paivaraha +
                    datum.tyottomyysetuus +
                    datum.kotihoidontuki, kaikki_tulot_ka: (datum.tyotulot +
                    datum.tyotulot_yrittaja +
                    datum.opintoraha +
                    datum.vanhempain_isyys_aitiys_paivaraha +
                    datum.tyottomyysetuus +
                    datum.kotihoidontuki) /
                    datum.henkiloita, perustilisaldo_ka: datum.perustilisaldo / datum.henkiloita, perustilisaldo_rajoitettu_ka: datum.perustilisaldo_rajoitettu / datum.henkiloita, perustilisaldo_kynnys_ka: datum.perustilisaldo_kynnys / datum.henkiloita, perustilisaldo_rajoitettu_kynnys_ka: datum.perustilisaldo_rajoitettu_kynnys / datum.henkiloita, perustilisaldoOP_ka: datum.perustilisaldoOP / datum.henkiloita, perustilisaldoOP_rajoitettu_ka: datum.perustilisaldoOP_rajoitettu / datum.henkiloita, perustilisaldoOP_kynnys_ka: datum.perustilisaldoOP_kynnys / datum.henkiloita, perustilisaldoOP_rajoitettu_kynnys_ka: datum.perustilisaldoOP_rajoitettu_kynnys / datum.henkiloita, etuussaldo_ka: datum.etuussaldo / datum.henkiloita, etuussaldo_rajoitettu_ka: datum.etuussaldo_rajoitettu / datum.henkiloita, etuussaldo_kynnys_ka: datum.etuussaldo_kynnys / datum.henkiloita, etuussaldo_rajoitettu_kynnys_ka: datum.etuussaldo_rajoitettu_kynnys / datum.henkiloita, etuussaldoOpintorahaKorotettu_ka: datum.etuussaldoOpintorahaKorotettu / datum.henkiloita, etuussaldoOpintorahaKorotettu_rajoitettu_ka: datum.etuussaldoOpintorahaKorotettu_rajoitettu / datum.henkiloita, etuussaldoOpintorahaKorotettu_kynnys_ka: datum.etuussaldoOpintorahaKorotettu_kynnys / datum.henkiloita, etuussaldoOpintorahaKorotettu_rajoitettu_kynnys_ka: datum.etuussaldoOpintorahaKorotettu_rajoitettu_kynnys /
                    datum.henkiloita });
        });
    })
        .valueOf();
    return summaryData;
};
var dataSetFileNames = {
    'Toteutuneen mukainen kulutus': 'etuussaldo',
    'Toteutuneen mukainen, 650 € opintoraha': 'etuussaldoOpintorahaKorotettu',
    'Maksiminostot, 250 € opintoraha': 'perustilisaldo',
    Maksiminostot: 'perustilisaldoOP',
};
var limitationFileNames = {
    'Ei rajoitettu': '',
    Rajoitettu: '_rajoitettu',
};
var thresholdFileNames = {
    'Ei kynnystä': '',
    Kynnys: '_kynnys',
};
export var getDataSetKey = function (dataSet, threshold, limitation) {
    return "" + dataSetFileNames[dataSet] + limitationFileNames[limitation] + thresholdFileNames[threshold];
};
var getDataSetFileName = function (dataSet, threshold, limitation) { return "/data/taulukko1_" + getDataSetKey(dataSet, threshold, limitation) + ".csv"; };
export var fetchAllData = function (emptyDataSet, updateDataSets) { return __awaiter(void 0, void 0, void 0, function () {
    var fetchOptions, fetchResults, added, dataSetAmount, addToFetchResults;
    return __generator(this, function (_a) {
        fetchOptions = [];
        Object.keys(dataSetFileNames).forEach(function (dataSet) {
            Object.keys(limitationFileNames).forEach(function (limitation) {
                Object.keys(thresholdFileNames).forEach(function (threshold) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        fetchOptions.push({
                            dataSet: dataSet,
                            threshold: threshold,
                            limitation: limitation,
                        });
                        return [2 /*return*/];
                    });
                }); });
            });
        });
        fetchResults = cloneDeep(emptyDataSet);
        added = 0;
        dataSetAmount = dataSetNames.length * thresholds.length * limitations.length;
        addToFetchResults = function (data, dataSet, threshold, limitation) {
            fetchResults[dataSet][threshold][limitation] = data;
            added++;
            if (added === dataSetAmount) {
                updateDataSets(fetchResults);
            }
        };
        fetchOptions.forEach(function (option) { return __awaiter(void 0, void 0, void 0, function () {
            var response, text, parsed, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(getDataSetFileName(option.dataSet, option.threshold, option.limitation))];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.text()];
                    case 2:
                        text = _a.sent();
                        parsed = dsvFormat(';').parse(text, function (row) {
                            return Object.keys(row).reduce(function (result, key) {
                                var _a;
                                var value = row[key];
                                var numberValue = Number(value);
                                return __assign(__assign({}, result), (_a = {}, _a[key] = isNumber(numberValue) && !isNaN(numberValue)
                                    ? numberValue
                                    : value, _a));
                            }, {});
                        });
                        data = getDataFromDataSet(parsed);
                        addToFetchResults(data, option.dataSet, option.threshold, option.limitation);
                        return [2 /*return*/];
                }
            });
        }); });
        return [2 /*return*/];
    });
}); };
export var fetchDataSet = function (initialDataSet, updateDataSets, dataSet, threshold, limitation) { return __awaiter(void 0, void 0, void 0, function () {
    var fetchResults, response, text, parsed, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                fetchResults = cloneDeep(initialDataSet);
                return [4 /*yield*/, fetch(getDataSetFileName(dataSet, threshold, limitation))];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.text()];
            case 2:
                text = _a.sent();
                parsed = dsvFormat(';').parse(text, function (row) {
                    return Object.keys(row).reduce(function (result, key) {
                        var _a;
                        var value = row[key];
                        var numberValue = Number(value);
                        return __assign(__assign({}, result), (_a = {}, _a[key] = isNumber(numberValue) && !isNaN(numberValue) ? numberValue : value, _a));
                    }, {});
                });
                data = getDataFromDataSet(parsed);
                fetchResults[dataSet][threshold][limitation] = data;
                updateDataSets(fetchResults);
                return [2 /*return*/];
        }
    });
}); };
var getDataFromDataSet = function (baseData) {
    var baseAccountData = resolveBaseAccountData(baseData);
    var monthlyData = getMonthlyClusterData(baseData);
    var clusterSizes = getClusterSizes(monthlyData);
    return { baseAccountData: baseAccountData, monthlyData: monthlyData, clusterSizes: clusterSizes };
};
export var flattenData = function (data) {
    var flatData = {};
    Object.keys(data).forEach(function (dataSetKey) {
        return ['Ei kynnystä', 'Kynnys'].forEach(function (threshold) {
            return ['Ei rajoitettu', 'Rajoitettu'].forEach(function (limitation) {
                var _a;
                flatData[dataSetKey + "-" + threshold + "-" + limitation] =
                    (_a = data[dataSetKey][threshold][limitation]) === null || _a === void 0 ? void 0 : _a.baseAccountData;
            });
        });
    });
    return flatData;
};
