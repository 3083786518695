var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ArticleContent from './components/ArticleContent';
import { AppSubTitle, AppTitle } from './components/common-styled-components';
import { MainNavigation } from './components/Navigation';
import ToolContent from './components/ToolContent';
import { AppContext, defaultContext } from './context';
import { fetchSummaryData } from './data-handlers/resolve-data';
import { breakpoints, theme } from './theme';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
var AppMain = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  align-items: center;\n  flex-wrap: nowrap;\n  ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  align-items: center;\n  flex-wrap: nowrap;\n  ", ";\n"])), theme.fontNormal);
var Logos = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: flex-end;\n  padding: ", " 0;\n"], ["\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: flex-end;\n  padding: ", " 0;\n"])), theme.spacing(4));
var Header = styled.header(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  background-size: cover;\n  padding: ", " 0;\n"], ["\n  width: 100%;\n  background-size: cover;\n  padding: ", " 0;\n"])), theme.spacing(7));
import headerBg from '../images/header-bg.png';
import { ItlaLogo } from './components/icons';
import mfgLogo from '../images/mfg40logo.png';
import Quotes from './components/Quotes';
var Ampersand = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  height: 44px;\n  line-height: 44px;\n  margin: 0 ", ";\n  color: ", ";\n\n  @media (max-width: ", "px) {\n    height: 32px;\n    line-height: 32px;\n    margin: 0 ", ";\n    ", ";\n  }\n"], ["\n  ", ";\n  ", ";\n  height: 44px;\n  line-height: 44px;\n  margin: 0 ", ";\n  color: ", ";\n\n  @media (max-width: ", "px) {\n    height: 32px;\n    line-height: 32px;\n    margin: 0 ", ";\n    ", ";\n  }\n"])), theme.fontBold, theme.fontSize(4), theme.spacing(3), theme.colors.blackText, breakpoints.mobile, theme.spacing(2), theme.fontSize(3));
var ItlaLogoLink = styled.a(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: block;\n  height: 60px;\n  width: 195px;\n\n  @media (max-width: ", "px) {\n    height: 43px;\n    width: 140px;\n  }\n"], ["\n  display: block;\n  height: 60px;\n  width: 195px;\n\n  @media (max-width: ", "px) {\n    height: 43px;\n    width: 140px;\n  }\n"])), breakpoints.mobile);
var MFGLogoLink = styled.a(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: block;\n  height: 44px;\n\n  @media (max-width: ", "px) {\n    height: 32px;\n  }\n"], ["\n  display: block;\n  height: 44px;\n\n  @media (max-width: ", "px) {\n    height: 32px;\n  }\n"])), breakpoints.mobile);
var MFGLogo = styled.img(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  height: 44px;\n\n  @media (max-width: ", "px) {\n    height: 32px;\n  }\n"], ["\n  height: 44px;\n\n  @media (max-width: ", "px) {\n    height: 32px;\n  }\n"])), breakpoints.mobile);
export default (function () {
    var _a = useState(defaultContext.selections), selections = _a[0], setSelections = _a[1];
    var _b = useState(defaultContext.dataSets), dataSets = _b[0], setDataSets = _b[1];
    var _c = useState(), summaryData = _c[0], setSummaryData = _c[1];
    useEffect(function () {
        if (!summaryData) {
            fetchSummaryData(setSummaryData);
        }
    }, []);
    var content = useRef(null);
    return (_jsx(Router, { children: _jsx(AppContext.Provider, __assign({ value: {
                selections: selections,
                setSelections: setSelections,
                dataSets: dataSets,
                setDataSets: setDataSets,
                summaryData: summaryData,
                setSummaryData: setSummaryData,
            } }, { children: _jsxs(AppMain, { children: [_jsxs(Logos, { children: [_jsx(ItlaLogoLink, __assign({ target: "_blank", href: "https://www.itla.fi" }, { children: _jsx(ItlaLogo, {}, void 0) }), void 0),
                            _jsx(Ampersand, { children: '&' }, void 0),
                            _jsx(MFGLogoLink, __assign({ target: "_blank", href: "https://mfg40.fi/" }, { children: _jsx(MFGLogo, { src: mfgLogo }, void 0) }), void 0)] }, void 0),
                    _jsxs(Header, __assign({ style: { backgroundImage: "url(" + headerBg + ")" } }, { children: [_jsx(AppTitle, { children: "Perustili" }, void 0),
                            _jsx(AppSubTitle, { children: "Tie kannustavaan ja la\u0308pina\u0308kyva\u0308a\u0308n sosiaaliturvaan \u2013 verkkoartikkeli ja ideapaperi" }, void 0),
                            _jsx("div", __assign({ onClick: function () { var _a; return (_a = content === null || content === void 0 ? void 0 : content.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' }); } }, { children: _jsx(MainNavigation, { type: "section", align: 'center', negative: true }, void 0) }), void 0)] }), void 0),
                    _jsx("section", { ref: content }, void 0),
                    _jsxs(Switch, { children: [_jsx(Route, __assign({ exact: true, path: "/tyokalut" }, { children: _jsx(ToolContent, {}, void 0) }), void 0),
                            _jsx(Route, __assign({ path: "/sanottua" }, { children: _jsx(Quotes, {}, void 0) }), void 0),
                            _jsx(Route, __assign({ exact: true, path: "/" }, { children: _jsx(ArticleContent, {}, void 0) }), void 0)] }, void 0)] }, void 0) }), void 0) }, void 0));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
