var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { texts } from '../../texts';
import { getFill } from '../../theme';
import { allClusters } from '../../types';
import { Grid, Legend, LegendItem } from '../common-styled-components';
import ClusterSummaryGraphNegative from './ClusterSummaryGraphNegative';
var GraphWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var ClusterSummaryGrid = function (_a) {
    var summaryData = _a.summaryData, clusters = _a.clusters, frame = _a.frame, showAmounts = _a.showAmounts, hideDetails = _a.hideDetails, hideTitles = _a.hideTitles;
    var cumulative = frame === 4;
    var positiveKeys = [];
    if (frame === undefined || frame === 3) {
        positiveKeys = ['tyotulot_yhteensa_ka'];
    }
    if (cumulative) {
        positiveKeys = ['tyotulot_yhteensa_ka_kumulatiivinen'];
    }
    var negativeKeys = [];
    if (cumulative) {
        negativeKeys = [
            'tyottomyysetuus_ka_kumulatiivinen',
            'opintoraha_ka_kumulatiivinen',
        ];
    }
    else {
        negativeKeys = ['tyottomyysetuus_ka', 'opintoraha_ka'].slice(frame === undefined || frame > 2 ? 0 : 2 - frame);
    }
    var clusterMaxValues = clusters.reduce(function (result, cluster) { return ({
        positiveMax: Math.max(result.positiveMax, Math.max.apply(Math, summaryData[cluster].map(function (d) {
            return cumulative
                ? d.tyotulot_yhteensa_ka_kumulatiivinen
                : d.tyotulot_yhteensa_ka;
        }))),
        negativeMax: Math.max(result.negativeMax, Math.max.apply(Math, summaryData[cluster].map(function (d) {
            return cumulative
                ? d.etuudet_yhteensa_ka_kumulatiivinen
                : d.etuudet_yhteensa_ka;
        }))),
    }); }, { positiveMax: 0, negativeMax: 0 });
    return (_jsxs("div", { children: [_jsx(Legend, { children: negativeKeys.concat(positiveKeys).map(function (key) { return (_jsx(LegendItem, { color: getFill(key), text: texts[key] }, key)); }) }, void 0),
            _jsx(Grid, __assign({ full: clusters.length === 1 }, { children: clusters
                    .sort(function (c1, c2) {
                    return allClusters.indexOf(c1) - allClusters.indexOf(c2);
                })
                    .map(function (cluster) { return (_jsx(GraphWrapper, { children: _jsx(ClusterSummaryGraphNegative, { title: hideTitles ? '' : texts[cluster], showAmount: showAmounts, summaryData: summaryData[cluster], positiveKeys: positiveKeys, negativeKeys: negativeKeys, positiveMax: clusterMaxValues.positiveMax, negativeMax: clusterMaxValues.negativeMax, hideAxis: hideDetails }, void 0) }, cluster)); }) }), void 0)] }, void 0));
};
var templateObject_1;
