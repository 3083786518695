var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { theme } from '../../theme';
import chroma from 'chroma-js';
import { withParentDimensions, } from './withParentDimensions';
var Box = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: ", ";\n  margin: ", " 0;\n  width: 100%;\n  background-color: ", ";\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n"], ["\n  padding: ", ";\n  margin: ", " 0;\n  width: 100%;\n  background-color: ", ";\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n"])), theme.spacing(4), theme.spacing(4), theme.colors.lightGrey);
var BoxContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  overflow: hidden;\n  transition: height 1s ease;\n"], ["\n  position: relative;\n  overflow: hidden;\n  transition: height 1s ease;\n"])));
var hex2rgba = function (hex, alpha) {
    if (alpha === void 0) { alpha = 1; }
    return chroma(hex).alpha(alpha).css();
};
var Fade = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  height: 100%;\n  width: 100%;\n  background: linear-gradient(\n    ", ",\n    ", ",\n    ", "\n  );\n  transition: opacity 1s linear;\n  pointer-events: none;\n"], ["\n  position: absolute;\n  top: 0;\n  left: 0;\n  height: 100%;\n  width: 100%;\n  background: linear-gradient(\n    ", ",\n    ", ",\n    ", "\n  );\n  transition: opacity 1s linear;\n  pointer-events: none;\n"])), hex2rgba(theme.colors.lightGrey, 0), hex2rgba(theme.colors.lightGrey, 0), hex2rgba(theme.colors.lightGrey));
var ToggleButton = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: ", " ", ";\n  margin-top: ", ";\n  background-color: transparent;\n  border: none;\n  ", ";\n  ", ";\n  color: ", ";\n  cursor: pointer;\n"], ["\n  padding: ", " ", ";\n  margin-top: ", ";\n  background-color: transparent;\n  border: none;\n  ", ";\n  ", ";\n  color: ", ";\n  cursor: pointer;\n"])), theme.spacing(1), theme.spacing(3), theme.spacing(2), theme.fontBold, theme.fontSize(0), theme.colors.blue);
var FactBox = function (props) {
    var _a;
    var COLLAPSED_HEIGHT = 130;
    var contentRef = React.useRef();
    var _b = useState(props.collapsed === true), collapsed = _b[0], setCollapsed = _b[1];
    var _c = useState(((_a = contentRef.current) === null || _a === void 0 ? void 0 : _a.clientHeight) || COLLAPSED_HEIGHT), contentHeight = _c[0], setContentHeight = _c[1];
    var updateContentHeight = function (isCollapsed) {
        var _a;
        var newHeight = isCollapsed
            ? COLLAPSED_HEIGHT
            : ((_a = contentRef.current) === null || _a === void 0 ? void 0 : _a.clientHeight) || 0;
        if (newHeight !== contentHeight) {
            setContentHeight(newHeight);
        }
    };
    var toggleCollapse = function () {
        var newCollapseState = !collapsed;
        updateContentHeight(newCollapseState);
        setCollapsed(newCollapseState);
    };
    useEffect(function () {
        updateContentHeight(collapsed);
    });
    return (_jsxs(Box, { children: [_jsxs(BoxContent, __assign({ style: { height: props.collapsed ? contentHeight : 'auto' } }, { children: [_jsx("div", __assign({ ref: contentRef }, { children: props.children }), void 0),
                    _jsx(Fade, { style: { opacity: collapsed ? '1' : '0' } }, void 0)] }), void 0),
            collapsed ? (_jsx(ToggleButton, __assign({ onClick: function () { return toggleCollapse(); } }, { children: "N\u00E4yt\u00E4 lis\u00E4\u00E4" }), void 0)) : undefined] }, void 0));
};
export default withParentDimensions(FactBox);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
