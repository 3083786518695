var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { texts } from '../../texts';
import { theme } from '../../theme';
import { allClusters } from '../../types';
import { Grid, Legend, LegendItem } from '../common-styled-components';
import ClusterAccountUseGraph from './ClusterAccountUseGraph';
var GraphWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var ClusterAccountUseGrid = function (_a) {
    var clusterData = _a.clusterData, clusters = _a.clusters;
    return (_jsxs("div", { children: [_jsx(Legend, { children: ['tili_taynna', 'tili_kaytossa', 'tili_nollilla'].map(function (key) { return (_jsx(LegendItem, { color: theme.getFill(key), text: texts[key] }, key)); }) }, void 0),
            _jsx(Grid, __assign({ full: clusters.length === 1 }, { children: clusters
                    .sort(function (c1, c2) {
                    return allClusters.indexOf(c1) - allClusters.indexOf(c2);
                })
                    .map(function (cluster) { return (_jsx(GraphWrapper, { children: _jsx(ClusterAccountUseGraph, { title: texts[cluster], clusterData: clusterData[cluster] }, void 0) }, cluster)); }) }), void 0)] }, void 0));
};
var templateObject_1;
