var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { allClusters, } from './types';
export var defaultContext = {
    selections: {
        selectedClusters: allClusters.filter(function (d) { return d !== 'Deceased' && d !== 'Not much or no data'; }),
        selectedDataSet: 'Toteutuneen mukainen, 650 € opintoraha',
        selectedThreshold: 'Kynnys',
        selectedLimitation: 'Rajoitettu',
        selectedTool: 'accountUse',
    },
    setSelections: function (_selections) { },
    dataSets: {
        'Toteutuneen mukainen kulutus': {
            'Ei kynnystä': {
                'Ei rajoitettu': undefined,
                Rajoitettu: undefined,
            },
            Kynnys: {
                'Ei rajoitettu': undefined,
                Rajoitettu: undefined,
            },
        },
        'Toteutuneen mukainen, 650 € opintoraha': {
            'Ei kynnystä': {
                'Ei rajoitettu': undefined,
                Rajoitettu: undefined,
            },
            Kynnys: {
                'Ei rajoitettu': undefined,
                Rajoitettu: undefined,
            },
        },
        Maksiminostot: {
            'Ei kynnystä': {
                'Ei rajoitettu': undefined,
                Rajoitettu: undefined,
            },
            Kynnys: {
                'Ei rajoitettu': undefined,
                Rajoitettu: undefined,
            },
        },
        'Maksiminostot, 250 € opintoraha': {
            'Ei kynnystä': {
                'Ei rajoitettu': undefined,
                Rajoitettu: undefined,
            },
            Kynnys: {
                'Ei rajoitettu': undefined,
                Rajoitettu: undefined,
            },
        },
    },
    setDataSets: function (_dataSets) { },
    setSummaryData: function (_summaryData) { },
};
export var AppContext = React.createContext(defaultContext);
export var partialUpdate = function (original, update) { return (__assign(__assign({}, original), update)); };
