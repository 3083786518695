var allSections = ['article', 'tool'];
export var isSection = function (value) {
    return typeof value === 'string' && allSections.includes(value);
};
export var allClusters = [
    'yhteensa',
    'Education/with study benefit',
    'Employment/with study benefit',
    'Childcare',
    'Employment after difficulties',
    'Fragmented employment / unemployment with secondary lvl study bnf',
    'Fragmented no data / education / employment',
    'Fragmented unemployment / income support',
    'Not much or no data',
    'Deceased',
];
export var isCluster = function (value) {
    return typeof value === 'string' && allClusters.includes(value);
};
var tools = ['accountUse', 'accountBalance', 'roleSummary'];
export var isTool = function (value) {
    return typeof value === 'string' && tools.includes(value);
};
export var dataSetNames = [
    'Toteutuneen mukainen kulutus',
    'Toteutuneen mukainen, 650 € opintoraha',
    'Maksiminostot',
    'Maksiminostot, 250 € opintoraha',
];
export var isDataSet = function (value) {
    return typeof value === 'string' && dataSetNames.includes(value);
};
export var limitations = ['Rajoitettu', 'Ei rajoitettu'];
export var isLimitation = function (value) {
    return typeof value === 'string' && limitations.includes(value);
};
export var thresholds = ['Kynnys', 'Ei kynnystä'];
export var isThreshold = function (value) {
    return typeof value === 'string' && thresholds.includes(value);
};
