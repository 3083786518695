var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { withParentDimensions, } from '../generic/withParentDimensions';
import { scaleLinear } from 'd3-scale';
import { texts } from '../../texts';
import { GraphSVG, GraphTitle } from '../common-styled-components';
import { Tick } from './Tick';
import { findIndex, max, min } from 'lodash';
import { curveLinear, line } from 'd3-shape';
import { theme } from '../../theme';
var ClusterAccountUseGraph = function (_a) {
    var baseData = _a.baseData, cluster = _a.cluster, parentDimensions = _a.parentDimensions, percentile = _a.percentile;
    var height = parentDimensions.width * 0.5;
    var clusterData = baseData[cluster];
    var margins = {
        top: 5,
        bottom: 20,
        right: 10,
        left: 60,
    };
    var scaleX = scaleLinear()
        .range([margins.left, parentDimensions.width - margins.right])
        .domain([0, clusterData.length - 1]);
    var values = clusterData.map(function (d) { return d[percentile]; });
    var minAmount = min(values);
    var maxAmount = max(values);
    var minMaxAmount = [
        minAmount !== undefined ? minAmount : 0,
        maxAmount !== undefined ? maxAmount : 20000,
    ];
    var scaleY = scaleLinear()
        .range([height - margins.bottom, margins.top])
        .domain(minMaxAmount);
    var lineGenerator = line()
        .y(function (d) { return scaleY(d[percentile]); })
        .x(function (d) { return findX(d.kuukausi); })
        .curve(curveLinear);
    var findX = function (month) {
        return scaleX(findIndex(clusterData, function (d) { return d.kuukausi === month; }));
    };
    return (_jsxs("div", { children: [_jsx(GraphTitle, { children: texts[cluster] }, void 0),
            _jsxs(GraphSVG, __assign({ width: parentDimensions.width, height: height }, { children: [_jsxs(Tick, __assign({ side: 'left', x: margins.left, y: scaleY(minMaxAmount[1]) }, { children: [Math.round(minMaxAmount[1]).toLocaleString('fi-FI'), " \u20AC"] }), void 0),
                    minMaxAmount[0] < 0 ? (_jsxs(_Fragment, { children: [_jsx(Tick, __assign({ side: 'left', x: margins.left, y: scaleY(0) }, { children: "0 \u20AC" }), void 0),
                            _jsx("line", { x1: margins.left, x2: parentDimensions.width - margins.right, y1: scaleY(0), y2: scaleY(0), stroke: theme.colors.grey(3) }, void 0)] }, void 0)) : undefined,
                    _jsxs(Tick, __assign({ side: 'left', x: margins.left, y: scaleY(minMaxAmount[0]) }, { children: [Math.round(minMaxAmount[0]).toLocaleString('fi-FI'), " \u20AC"] }), void 0),
                    _jsx(Tick, __assign({ side: 'bottom', x: findX('2005-01'), y: height - margins.bottom }, { children: "18 v" }), void 0),
                    _jsx(Tick, __assign({ side: 'bottom', x: findX('2015-01'), y: height - margins.bottom }, { children: "28 v" }), void 0),
                    _jsx("path", { d: lineGenerator(clusterData) || '', stroke: theme.colors.blue, strokeWidth: 3, fill: "none", strokeLinecap: "square", strokeLinejoin: "round" }, void 0)] }), void 0)] }, void 0));
};
export default withParentDimensions(ClusterAccountUseGraph);
