var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import styled from 'styled-components';
import { theme } from '../theme';
import { ArticleMain, ArticleSubtitleNoMargin, Bold, FactBoxTitle, Italic, P, ShareLink, TextContent, } from './common-styled-components';
import FactBox from './generic/FactBox';
var WriterImage = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-width: 240px;\n  width: 100%;\n  margin: ", " 0;\n"], ["\n  max-width: 240px;\n  width: 100%;\n  margin: ", " 0;\n"])), theme.spacing(3));
import liisaBjorklundImage from '../../images/liisabjorklund.jpg';
import mauriKotamakiImage from '../../images/maurikotamaki.jpg';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { shareToClipBoard } from '../utils';
var ShareButton = function (props) {
    return (_jsx(ShareLink, __assign({ onClick: function () {
            shareToClipBoard("https://perustilisimulaatio.fi/sanottua/" + props.writer);
            props.setShared(props.writer);
        } }, { children: props.shared !== props.writer
            ? 'Jaa tämä teksti'
            : 'Linkki kopioitu leikepöydälle!' }), void 0));
};
var Texts = function () {
    var _a = useState(), shared = _a[0], setShared = _a[1];
    var highlightedWriter = useParams().highlightedWriter;
    var liisaBjorklund = (_jsxs(_Fragment, { children: [_jsx(ArticleSubtitleNoMargin, { children: "Samaa kaikille vai vaikuttavaa jokaiselle?" }, void 0),
            _jsx(P, { children: _jsx(Italic, { children: "Liisa Bj\u00F6rklund" }, void 0) }, void 0),
            _jsx(P, { children: "Teknologia mahdollistaa nykyist\u00E4 kohdennetummat kannustimet, jotka lis\u00E4\u00E4v\u00E4t ihmisten omaehtoisuutta ja ty\u00F6llistymismahdollisuuksia. Tulevaisuudessa teko\u00E4ly voi \u201Clempe\u00E4sti tuuppia\u201D nuoria t\u00F6ihin." }, void 0),
            _jsx(P, { children: "Sosiaaliturvaj\u00E4rjestelm\u00E4mme on rakennettu aikana, jolloin ty\u00F6markkinat olivat on/off. T\u00F6iss\u00E4 joko oltiin tai ei oltu. T\u00E4llainen statuksen todisteluun perustuva j\u00E4rjestelm\u00E4 on edellytt\u00E4nyt paljon byrokraatteja paljon ammattilaisia k\u00E4ytt\u00E4m\u00E4\u00E4n aikaansa selvittelyty\u00F6h\u00F6n. T\u00E4m\u00E4 on tarkoittanut usein \u201Csamalla kauhalla kaikille\u201D-periaatteen toteuttamista." }, void 0),
            _jsx(P, { children: "Kannustavuuden n\u00E4k\u00F6kulmasta t\u00E4m\u00E4 on ongelmallista. Kannustimet toimivat sit\u00E4 tehokkaammin, mit\u00E4 paremmin ne osuvat yksiin kansalaisen omien intressien kanssa. Kannustin muuttuu pakoksi tilanteessa, jossa sen osoittamat toimintavaihtoehdot eiv\u00E4t ole kannustettavan intressien mukaisia. Mit\u00E4 suurempi vapaus ihmisell\u00E4 on valita itselleen t\u00E4rkeit\u00E4 asioita, sit\u00E4 suuremmalla todenn\u00E4k\u00F6isyydell\u00E4 h\u00E4n toimii my\u00F6s yhteiskunnan kannalta toivotulla tavalla." }, void 0),
            _jsx(FactBoxTitle, { children: "Digitalisaatio mahdollistaa yhteiskunnan kannustinj\u00E4rjestelmien merkitt\u00E4v\u00E4n tehostamisen." }, void 0),
            _jsx(P, { children: "Teko\u00E4ly\u00E4 hy\u00F6dynt\u00E4m\u00E4ll\u00E4 ihmisten yksil\u00F6lliset intressit ja el\u00E4m\u00E4ntilanteet voitaisiin ennakoida nykyist\u00E4 tarkemmin ja kohdentaa resursseja t\u00E4m\u00E4n tiedon perusteella." }, void 0),
            _jsx(P, { children: "Suomessa on valtavasti tietoa nuorista ja heid\u00E4n koulutus- ja urapoluistaan. Rekisteridatan pohjalta voidaan ennustaa kunkin yksil\u00F6n ty\u00F6llistymistodenn\u00E4k\u00F6isyyksi\u00E4. Sielt\u00E4 voidaan tunnistaa my\u00F6s merkitt\u00E4vimm\u00E4t syrj\u00E4ytymisen riskit ja kannustinloukut." }, void 0),
            _jsx(P, { children: "Rekisteridata kertoo, ett\u00E4 85 prosenttia nuorista kannustuu toimimaan yhteiskunnan kannalta hy\u00F6dyllisell\u00E4 tavalla, he suorittavat kohtuuajassa tutkinnon ja siirtyv\u00E4t ty\u00F6markkinoiden palvelukseen. Heill\u00E4 on kyky\u00E4 hy\u00F6ty\u00E4 julkisista palveluista ja tuista tarkoituksenmukaisesti, lis\u00E4ksi useimmat heist\u00E4 saa tukea vanhemmiltaan ja l\u00E4hiverkostoiltaan." }, void 0),
            _jsx(P, { children: "Noin viidesosa nuorista tarvitsee jossakin vaiheessa tukea saavuttaakseen riitt\u00E4v\u00E4n koulutustason ja ty\u00F6llisty\u00E4kseen. Heillekin yhteiskunnan peruspalvelut ovat monilta osin riitt\u00E4vi\u00E4 mutta lis\u00E4tuen saamiseksi on n\u00E4ht\u00E4v\u00E4 vaivaa. Esimerkiksi mielenterveyden ongelmiin avun saaminen on eritt\u00E4in vaikeaa, mist\u00E4 syyst\u00E4 monen nuoren ahdinko pitkittyy ja riski syrj\u00E4yty\u00E4 kasvaa. Heid\u00E4n ty\u00F6llistymistodenn\u00E4k\u00F6isyytens\u00E4 laskee merkitt\u00E4v\u00E4sti ajan kuluessa." }, void 0),
            _jsx(P, { children: "Alle kymmenell\u00E4 prosentilla nuorista ei mene kovin hyvin. N\u00E4ille nuorille on kasautunut useampia riskitekij\u00F6it\u00E4 el\u00E4m\u00E4ss\u00E4\u00E4n, eik\u00E4 vanhempien ja l\u00E4hiverkoston tukea ole aina saatavilla. Heid\u00E4n ty\u00F6llistymistodenn\u00E4k\u00F6isyytens\u00E4 on heikko, voidakseen hakeutua ty\u00F6markkinoille on ensin laitettava arki kuntoon." }, void 0),
            _jsx(P, { children: "Tied\u00E4mme n\u00E4iden nuorten el\u00E4m\u00E4npolkujen kriittiset k\u00E4\u00E4nnekohdat, joissa oikein asetetuilla kannustimilla voitaisiin ehk\u00E4ist\u00E4 syrj\u00E4ytymist\u00E4. Vahvempaa tukea tarvitsevat nuoret olisivat helposti tunnistettavissa huomattavasti nykyist\u00E4 varhemmin. Esimerkiksi toimeentulotuen saaminen lis\u00E4\u00E4 merkitt\u00E4v\u00E4sti ty\u00F6tt\u00F6myyden jatkumisen riski\u00E4 ja osallistumistulon malli voisi olla ratkaisu t\u00E4h\u00E4n kannustinloukkuongelmaan." }, void 0),
            _jsx(P, { children: "Tiedon puute ei siis ole ongelma vaan sen hy\u00F6dynt\u00E4minen tarkoituksenmukaisella ja tietosuojaa kunnioittavalla tavalla. Tutkimusten mukaan automatisoitu tiedonk\u00E4ytt\u00F6 lis\u00E4\u00E4 vaikuttavuutta ja yhdenvertaisuutta, kun inhimilliset arviointivirheet ja osaamisvajeet minimoidaan. Teko\u00E4ly voisi muotoilla yhdenvertaiset kriteerit tunnistaa kunkin yksil\u00F6lliset palvelutarpeet ja ennakoida resursseja niiden perusteella. Teko\u00E4ly voisi toimia uutta j\u00E4rjestelm\u00E4\u00E4 suojelevana byrokraattina, jolloin asiantuntijat voivat keskitty\u00E4 t\u00E4rke\u00E4mp\u00E4\u00E4n eli kohtaamaan tukea tarvitsevia nuoria." }, void 0),
            _jsx(FactBoxTitle, { children: "Lempe\u00E4 tuuppaus kohti ty\u00F6el\u00E4m\u00E4\u00E4" }, void 0),
            _jsx(P, { children: "Perustilin eetos sopii yksiin k\u00E4ytt\u00E4ytymistaloustieteess\u00E4 muotoillun kannustinajattelun (Nudge) kanssa. Sen jonka mukaan my\u00F6nteisen \u201Dtuuppauksen\u201D eli kannustamisen v\u00E4lityksell\u00E4 saadaan pakko- ja sanktiokeinoja tehokkaammin haluttuja vaikutuksia." }, void 0),
            _jsx(P, { children: "Suurimmalle osalle nuorista riitt\u00E4v\u00E4t lempe\u00E4t t\u00F6n\u00E4isyt opinto- ja urapolulla eteenp\u00E4in. Perustili tarjoaisi valtaosalle nuorista helpomman tavan k\u00E4ytt\u00E4\u00E4 yhteiskunnan tarjoamaa tukea hyv\u00E4kseen, he pystyisiv\u00E4t hallinnoimaan itse\u00E4\u00E4n koskevaa dataa ja nykyist\u00E4 itsen\u00E4isemmin suunnittelemaan tulevaisuuden polkuaan. Omaehtoisuuden lis\u00E4\u00E4ntymisen tiedet\u00E4\u00E4n jo itsess\u00E4\u00E4n toimivan tehokkaana kannustimena." }, void 0),
            _jsx(P, { children: "Niille, joiden tiedet\u00E4\u00E4n tarvitsevan vahvempaa tukea, tarjottaisiin tuuppaajien sijaan rinnalla kulkijoita. Vaikeuksiin joutuneita nuoria ei auteta pakoin eik\u00E4 sanktioin vaan tarjoamalla ongelmien juurisyyt tunnistavia palveluja. Ihminen kyll\u00E4 kannustuu koulunpenkille tai ty\u00F6markkinoille, kun h\u00E4nell\u00E4 on riitt\u00E4v\u00E4sti uskoa omiin mahdollisuuksiinsa ja osaamiseensa." }, void 0),
            _jsx(P, { children: "Perustilin filosofia on luottaa nuoren kykyyn ottaa vastuuta valinnoistaan ja k\u00E4ytt\u00E4\u00E4 resursseja itsens\u00E4 mutta my\u00F6s koko yhteiskunnan hyv\u00E4ksi. Oikein suunnattu luottamus on yhteiskunnalle edullinen sijoitus, koska luottamisella on tapana synnytt\u00E4\u00E4 luotettavuutta." }, void 0),
            _jsx(ShareButton, { writer: "liisaBjorklund", shared: shared, setShared: setShared }, void 0),
            _jsx(WriterImage, { src: liisaBjorklundImage }, void 0),
            _jsx(P, { children: _jsx(Italic, { children: "Liisa Bj\u00F6rklund on v\u00E4itellyt vuonna 2008 kannustamisen moraalista ja Suomessa harjoitetusta aktivointipolitiikasta. H\u00E4n on toiminut yli vuosikymmenen erityisryhmien palvelujen kehitt\u00E4misen johtoteht\u00E4viss\u00E4 ja etsinyt vaikuttavia ratkaisuja lasten, nuorten ja perheiden syrj\u00E4ytymisongelmaan. T\u00E4ll\u00E4 hetkell\u00E4 h\u00E4n muotoilee vaikuttavuuden mallinnuksia eri organisaatioille, kuten osaty\u00F6kykyisten ty\u00F6llistymisen vaikuttavuusperusteista ty\u00F6llistymisen mallia kuntakokeiluihin." }, void 0) }, void 0)] }, void 0));
    var mauriKotamaki = (_jsxs(_Fragment, { children: [_jsx(ArticleSubtitleNoMargin, { children: "Suomalainen sosiaaliturvaj\u00E4rjestelm\u00E4 huutaa uudistusta" }, void 0),
            _jsx(P, { children: _jsx(Italic, { children: "Mauri Kotam\u00E4ki" }, void 0) }, void 0),
            _jsx(P, { children: "Suomalainen sosiaaliturvamalli on ytimelt\u00E4\u00E4n toimiva, mutta j\u00E4rjestelm\u00E4n rakentaminen vuosi vuodelta, kerros kerrokselta on tehnyt siit\u00E4 monimutkaisen, paikoin sekavan ja ep\u00E4k\u00E4yt\u00E4nn\u00F6llisen. Tarinan mukaan vanhempainvapaista akateemista tutkimustakin tehneell\u00E4 henkil\u00F6ll\u00E4 oli vaikeuksia ymm\u00E4rt\u00E4\u00E4, kuinka vanhempainvapaiden kokonaisuus omalla kohdalla toimii. Perustulo- tai perustilimalli parantaisi j\u00E4rjestelm\u00E4n ymm\u00E4rrett\u00E4vyytt\u00E4 ja v\u00E4hent\u00E4isi byrokratialoukkuja." }, void 0),
            _jsxs(P, { children: ["Sosiaaliturvamallin sekavuudesta monet ovat samanmielisi\u00E4, mutta ristiriitaisempaa on suhtautuminen etuisuuksien velvoittavuuteen. Toisten mielest\u00E4 vastikkeeton tuki vapauttaa ihmisen, toiset tuntuvat ajattelevan vastikkeettoman rahan olevan tie turmioon. Asian vaikeudesta kertonee se, ett\u00E4 vuonna 2019 entinen p\u00E4\u00E4ministerimme Juha Sipil\u00E4 vaikutti kannattavan ", _jsx(Bold, { children: "vastikkeellista perustuloa" }, void 0), ". Termi on v\u00E4h\u00E4n kuin selv\u00E4p\u00E4inen juoppo tai hyv\u00E4kuntoinen sohvaperuna. N\u00E4kemist\u00E4ni ehdotuksista l\u00E4himm\u00E4ksi vastikkeellista perustuloa tulee Pullin, Pyykk\u00F6sen ja Pyykk\u00F6sen perustilimalli, jossa on poimittu osa-alueita niin vastikkeellisuudesta kuin vastikkeettomuudestakin."] }, void 0),
            _jsx(P, { children: "Tutkijoiden \u201Cperustilimallissa\u201D henkil\u00F6 p\u00E4\u00E4see tietyin ehdoin perustilin piiriin, jolloin sosiaaliturvan velvoittavuus ei yksil\u00F6\u00E4 koske. Kun perustilin rahat on k\u00E4ytetty, tippuu henkil\u00F6 osallistumistulon puolelle velvoittavien aktiivisen ty\u00F6voimapoliittisten toimenpiteiden piiriin. Oivallus on yksinkertaisuudessaan erinomainen ja osin ratkaisee yhden sosiaaliturvan keskeisist\u00E4 j\u00E4nnitteist\u00E4: kuinka kohdistaa aktiivisen ty\u00F6voimapolitiikan toimenpiteit\u00E4 ihmisiin, jotka toimenpiteist\u00E4 eniten hy\u00F6tyisiv\u00E4t tai k\u00E4\u00E4nt\u00E4en kuinka voidaan helpottaa sellaisten ihmisten byrokratiataakkaa, joille toimenpiteet olisivat v\u00E4hemm\u00E4n hy\u00F6dyllisi\u00E4." }, void 0),
            _jsx(FactBoxTitle, { children: "Tilimalli selkeytt\u00E4isi ja parantaisi kannusteita" }, void 0),
            _jsxs(P, { children: ["Jos saisin itsevaltiaan valtuudet suunnitella sosiaaliturvaj\u00E4rjestelm\u00E4 tyhj\u00E4lt\u00E4 p\u00F6yd\u00E4lt\u00E4, perustaisin (sosiaali)tilimallin, jossa vapauden ja vastuun suhde noudattaisi kirjoittajien jakoa perustiliin ja osallistumistuloon. Syit\u00E4 on monia, mutta keskeisin niist\u00E4 on j\u00E4rjestelm\u00E4n kannustavuus. Ei niink\u00E4\u00E4n kannustavuus efektiivisten rajaveroasteiden tai ty\u00F6tt\u00F6myysloukkujen n\u00E4k\u00F6kulmasta, vaan pikemminkin", ' ', _jsx(Italic, { children: "ihmisen oman el\u00E4m\u00E4n hallinnan tunteen" }, void 0), ",", ' ', _jsx(Italic, { children: "yritt\u00E4jyyden" }, void 0), " sek\u00E4 ", _jsx(Italic, { children: "elinkaariajattelun" }, void 0), ' ', "takia."] }, void 0),
            _jsxs(P, { children: ["Kannustimien n\u00E4k\u00F6kulmasta perustili voisi olla kuin turboahdettu perustulo. Nykyinen sosiaaliturva tai perustulo ovat staattisia j\u00E4rjestelmi\u00E4 siin\u00E4 mieless\u00E4, ett\u00E4 t\u00E4m\u00E4n p\u00E4iv\u00E4n toiminta ei (ainakaan merkitt\u00E4v\u00E4sti) vaikuta tulevaisuuden etuustasoon. Perustulon saa samansuuruisena t\u00E4n\u00E4\u00E4n, ensi kuussa ja viel\u00E4 ensi vuonnakin. Perustiliss\u00E4 asiat ovat toisin ja kyseess\u00E4 on t\u00E4ss\u00E4 mieless\u00E4", ' ', _jsx(Italic, { children: "dynaaminen" }, void 0), " malli. Kaikki t\u00E4ss\u00E4 hetkess\u00E4 tehdyt p\u00E4\u00E4t\u00F6kset vaikuttavat perustilin saldoon ja n\u00E4in ollen jokaisella p\u00E4\u00E4t\u00F6ksell\u00E4 on aidosti v\u00E4li\u00E4. Jos p\u00E4\u00E4t\u00E4t nostaa sosiaaliturvatililt\u00E4 nyt v\u00E4hemm\u00E4n rahaa, voit tulevaisuudessa nostaa enemm\u00E4n \u2013 ja p\u00E4invastoin. Sosiaalietuuksien k\u00E4ytt\u00F6 j\u00E4rkev\u00F6ityisi, kun jokainen tilin pennonen olisi aidosti tilin omistajan omaa rahaa. Viisaasti suunniteltuna elinkaariajattelu voi parantaa sosiaaliturvan toimivuutta."] }, void 0),
            _jsx(P, { children: "Edellisess\u00E4 kappaleessa kuvattu elinkaariajattelu toimii tosin vain silloin, kun j\u00E4rjestelm\u00E4 olisi integroitu el\u00E4kej\u00E4rjestelm\u00E4\u00E4n. Perustilin yhdist\u00E4minen el\u00E4kej\u00E4rjestelm\u00E4\u00E4n voitaisiin tehd\u00E4 esimerkiksi niin, ett\u00E4 osa tilin loppusummasta olisi el\u00E4k\u00F6itymisen yhteydess\u00E4 mahdollista nostaa rahana ja toinen osa muutettaisiin annuiteetiksi eli kuukausittain maksettavaksi el\u00E4kkeeksi. T\u00E4ll\u00E4 tavalla itse el\u00E4kej\u00E4rjestelm\u00E4n perusperiaatteisiin ei tarvitsisi ottaa kantaa uudistuksen yhteydess\u00E4." }, void 0),
            _jsx(P, { children: "Perustili kuten perustulokin parantaisi my\u00F6s yritt\u00E4jyyden kannusteita. Nykyinen sosiaaliturva on paitsi ep\u00E4selv\u00E4, my\u00F6s poissulkeva. K\u00E4yt\u00E4nn\u00F6ss\u00E4 ymm\u00E4rrett\u00E4vist\u00E4, mutta toisaalta ep\u00E4reilulta tuntuvien syiden takia yritt\u00E4j\u00E4t ovat monin paikoin suljettu sosiaaliturvan ulkopuolelle; sosiaaliturva on suunniteltu l\u00E4hinn\u00E4 palkansaajille, mutta maailman muuttuessa my\u00F6s sosiaaliturvan perustusten tulisi muuttua. Perustili ja -tulo ovat ainakin osittaisia vastauksia yritt\u00E4jyyden ja sosiaaliturvan yhteensovittamiseen." }, void 0),
            _jsx(P, { children: "Yritt\u00E4jill\u00E4 on palkansaajia helpommin mahdollista vaikuttaa tulojensa ajoitukseen. N\u00E4in ollen tarveharkintaisen sosiaaliturvan s\u00E4\u00E4nn\u00F6t eiv\u00E4t voi p\u00E4te\u00E4 samanlaisina sek\u00E4 yritt\u00E4jille ett\u00E4 palkansaajille \u2013 mutta vastikkeettomat etuudet voivat. Lis\u00E4ksi vastikkeeton perustili olisi todenn\u00E4k\u00F6isesti huomattavasti matalamman kynnyksen mahdollistaja aloittelevalle yritt\u00E4j\u00E4lle verrattuna nykyiseen starttirahaan." }, void 0),
            _jsx(FactBoxTitle, { children: "Joustoa hyv\u00E4ll\u00E4 alustalla" }, void 0),
            _jsx(P, { children: "Esitetty tilimalli on my\u00F6s kirjanpitotapa ja alusta, jonka puitteissa on mahdollista saavuttaa monenlaisia tavoitteita. Matemaattisesti ajateltuna ja hieman k\u00E4rjist\u00E4en nykyinen suomalainen sosiaaliturvaj\u00E4rjestelm\u00E4kin mahtuisi tilimallin sis\u00E4\u00E4n, kun parametrit vain s\u00E4\u00E4dett\u00E4isiin oikeaan asentoon." }, void 0),
            _jsx(P, { children: "Alustana tilimallin keskeinen etu on tehd\u00E4 sosiaaliturvan rahavirroista n\u00E4kyvi\u00E4 n\u00E4in k\u00E4ytt\u00E4j\u00E4n ep\u00E4varmuutta v\u00E4hent\u00E4en. Ihmiset eiv\u00E4t keskim\u00E4\u00E4rin rakasta ep\u00E4varmuutta ja sosiaaliturvan tapauksessa ep\u00E4varmuus on erityisen haitallista; tyyppiesimerkkein\u00E4 jatko-opintojen aloittaminen tai osa-aikainen ty\u00F6nteko aiheuttavat ep\u00E4varmuutta niin maksatuksen kuin maksuviiveidenkin suhteen. Perustili siin\u00E4 miss\u00E4 perustulokin poistaisi t\u00E4m\u00E4n ep\u00E4varmuuden, koska etuuden maksatus olisi automatisoitu ja riippumaton k\u00E4yt\u00E4nn\u00F6ss\u00E4 muusta kuin ty\u00F6tulosta." }, void 0),
            _jsx(P, { children: "Pyykk\u00F6sen ja kumppaneiden redusoitu perustilimalli eli tilimalli, joka korvaisi vain perusp\u00E4iv\u00E4rahan, ty\u00F6markkinatuen ja opintotuen, olisi vasta ensiaskel. Tilimallin puitteissa sosiaaliturvaj\u00E4rjestelm\u00E4n selkeytt\u00E4minen olisi itseisarvoisesti hy\u00F6dyllist\u00E4, mutta todellinen vaikuttavuus tulisi el\u00E4kej\u00E4rjestelm\u00E4n ja tilimallin yhdist\u00E4misest\u00E4. Mallin vaikuttavuuden ja nimenomaisesti elinkaariajattelun kannalta olisikin t\u00E4rke\u00E4\u00E4 pohtia syv\u00E4llisemmin tilimallin ja el\u00E4k\u00F6itymisen saranakohta heti alkuvaiheessa." }, void 0),
            _jsx(FactBoxTitle, { children: "Voisiko perustilimalli toteutua Suomessa" }, void 0),
            _jsx(P, { children: "Uudistaminen on vaikeaa, varsinkin sosiaaliturvan. J\u00E4rjestelm\u00E4t yksityiskohtaisesti tuntevat virkamiehet n\u00E4kev\u00E4t usein ensin vaikeuksia ja esteit\u00E4 mahdollisuuksien sijaan. P\u00E4\u00E4tt\u00E4j\u00E4t taas pelk\u00E4\u00E4v\u00E4t pienenkin h\u00E4vi\u00E4j\u00E4joukon pit\u00E4m\u00E4\u00E4 kovaa \u00E4\u00E4nt\u00E4. Luonnollisesti uudistaminen vaikeutuu entisest\u00E4\u00E4n, koska sosiaaliturvan tapauksessa puhutaan usein pienituloisista. Lis\u00E4ksi nyt j\u00E4rjestelmien vallan kahvassa olevien motiivi on s\u00E4ilytt\u00E4\u00E4 nykyinen valtatasapaino \u2013 toisin sanoin s\u00E4ilytt\u00E4\u00E4 nykyinen j\u00E4rjestelm\u00E4 ja uudistua rajatusti vain sen puitteissa." }, void 0),
            _jsx(P, { children: "T\u00E4st\u00E4 n\u00E4k\u00F6kulmasta viimeaikaiset avaukset niin perustulon, osallistumistulon kuin nyt perustilinkin suuntaan ovat ilahduttavan tervetulleita. Sata-komiteassa jokaisella taholla oli veto-oikeus ja ty\u00F6skentely oli haastavaa. Esimerkiksi yleisest\u00E4 ansioturvasta ei suostuttu edes keskustelemaan. Vaikka monia muutosehdotuksia Sata-komitean papereista onkin implementoitu, niin muutokset ovat j\u00E4rjestelm\u00E4n n\u00E4k\u00F6kulmasta tyyppi\u00E4 t\u00E5rta-p\u00E5-t\u00E5rta eik\u00E4 rakenteissa ole tapahtunut suurempia muutoksia." }, void 0),
            _jsx(P, { children: "Nyt istuvan sosiaaliturvakomitean puitteissa tarvittaisiin aiempaa rohkeampaa ajattelua. Aikaa oli kaksi vaalikautta, nyt jo v\u00E4hemm\u00E4n. Eduskuntapuolueiden tahdolla on iso rooli. Jos eduskunnan enemmist\u00E4 katsoo perustavanlaatuisemman remontin tarpeelliseksi, niin my\u00F6s tapahtuu. Muussa tapauksessa ihmettelemme sosiaaliturvan monimutkaisuutta ja sekavuutta viel\u00E4 2030-luvullakin." }, void 0),
            _jsx(ShareButton, { writer: "mauriKotamaki", shared: shared, setShared: setShared }, void 0),
            _jsx(WriterImage, { src: mauriKotamakiImage }, void 0),
            _jsx(P, { children: _jsx(Italic, { children: "Mauri Kotam\u00E4ki on Keskuskauppakamarin p\u00E4\u00E4ekonomisti, joka v\u00E4itteli vuonna 2017 sosiaaliturvaan ja verotukseen liittyvist\u00E4 aiheista." }, void 0) }, void 0)] }, void 0));
    var writers = {
        liisaBjorklund: liisaBjorklund,
        mauriKotamaki: mauriKotamaki,
    };
    var manualOrder = ['mauriKotamaki', 'liisaBjorklund'];
    return (_jsx(_Fragment, { children: Object.keys(writers)
            .sort(function (w1, w2) {
            return highlightedWriter === w1
                ? -1
                : highlightedWriter === w2
                    ? 1
                    : manualOrder.indexOf(w1) - manualOrder.indexOf(w2);
        })
            .map(function (writer) { return (_jsx(FactBox, __assign({ collapsed: highlightedWriter === undefined || highlightedWriter !== writer }, { children: writers[writer] }), writer)); }) }, void 0));
};
export default (function () {
    var path = useRouteMatch().path;
    return (_jsx(ArticleMain, { children: _jsx(TextContent, { children: _jsxs(Switch, { children: [_jsx(Route, __assign({ exact: true, path: path }, { children: _jsx(Texts, {}, void 0) }), void 0),
                    _jsx(Route, __assign({ path: path + "/:highlightedWriter" }, { children: _jsx(Texts, {}, void 0) }), void 0)] }, void 0) }, void 0) }, void 0));
});
var templateObject_1;
