var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { theme } from '../../theme';
var SpinnerContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  min-height: 300px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n"], ["\n  width: 100%;\n  min-height: 300px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n"])));
var SpinnerImage = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 40px;\n  height: 40px;\n\n  animation-name: spin;\n  animation-duration: 1500ms;\n  animation-iteration-count: infinite;\n  animation-timing-function: ease;\n\n  @keyframes spin {\n    from {\n      transform: rotate(0deg);\n    }\n    to {\n      transform: rotate(360deg);\n    }\n  }\n"], ["\n  width: 40px;\n  height: 40px;\n\n  animation-name: spin;\n  animation-duration: 1500ms;\n  animation-iteration-count: infinite;\n  animation-timing-function: ease;\n\n  @keyframes spin {\n    from {\n      transform: rotate(0deg);\n    }\n    to {\n      transform: rotate(360deg);\n    }\n  }\n"])));
var SpinnerText = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  ", ";\n  margin-top: ", ";\n  ", ";\n"], ["\n  color: ", ";\n  ", ";\n  margin-top: ", ";\n  ", ";\n"])), theme.colors.midGrey, theme.fontNormal, theme.spacing(1), theme.fontSize(-1));
import spinnerImage from '../../../images/spinner.png';
export var Spinner = function () {
    return (_jsxs(SpinnerContainer, { children: [_jsx(SpinnerImage, { src: spinnerImage }, void 0),
            _jsx(SpinnerText, { children: "Ladataan\u2026" }, void 0)] }, void 0));
};
var templateObject_1, templateObject_2, templateObject_3;
