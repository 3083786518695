export var texts = {
    tili_kaytossa: 'Perustili käytössä',
    tili_nollilla: 'Osallistumistulolla',
    tili_taynna: 'Perustili täynnä',
    yhteensa: 'Koko ikäluokka',
    Childcare: 'Lastenhoito',
    Deceased: 'Kuolleet',
    'Education/with study benefit': 'Korkea-asteen koulutus',
    'Employment after difficulties': 'Työssä vaikeuksien jälkeen',
    'Employment/with study benefit': 'Työssä, 2. asteen koulutus',
    'Fragmented employment / unemployment with secondary lvl study bnf': 'Pätkätöitä, 2. asteen koulutus',
    'Fragmented no data / education / employment': 'Vain työ- ja opintopätkiä',
    'Fragmented unemployment / income support': 'Työttömyys- tai toimeentulotuki',
    'Not much or no data': 'Vähän tietoja',
    clusters: 'Valitse ryhmät',
    dataSet: 'Valitse datasetti',
    tools: 'Valitse työkalu',
    section: '',
    tyottomyysetuus: 'Työttömyysetuudet',
    vanhempainetuudet: 'Vanhempainetuudet',
    opintoraha: 'Opintoraha',
    tyotulot_yhteensa: 'Työtulot',
    tyottomyysetuus_ka: 'Työttömyysetuudet',
    tyottomyysetuus_ka_kumulatiivinen: 'Työttömyysetuudet, kertymä',
    vanhempainetuudet_ka: 'Vanhempainetuudet',
    opintoraha_ka: 'Opintoraha',
    opintoraha_ka_kumulatiivinen: 'Opintoraha, kertymä',
    tyotulot_yhteensa_ka: 'Työtulot',
    tyotulot_yhteensa_ka_kumulatiivinen: 'Työtulot, kertymä',
    opiskelija: 'Opiskelija',
    tyoton: 'Työtön',
    tyollinen: 'Työllinen',
    'Toteutuneen mukainen kulutus-explanation': 'Simulaatioksi on valittu vuonna 1987 syntyneiden toteutuneen mukainen etuuksien kulutus.',
    'Toteutuneen mukainen, 650 € opintoraha-explanation': 'Simulaatioksi on valittu vuonna 1987 syntyneiden toteutuneen mukainen etuuksien kulutus korotetulla 650 € opintorahalla.',
    'Maksiminostot-explanation': 'Simulaatioksi on valittu malli, jossa perustililtä nostetaan aina 650 € kun se on mahdollista, eli ns. maksiminostomalli.',
    'Maksiminostot, 250 € opintoraha-explanation': 'Simulaatioksi on valittu malli, jossa työttömät nostavat perustililtä aina 650 € ja opiskelijat rajoitettua 250 € opintorahaa aina kun mahdollista.',
    'Kynnys-explanation': 'Tililtä saa nostaa rahaa vasta, kun kynnysehdot (5 opintokuukautta tai 15 000 € työtulot) ovat täyttyneet.',
    'Ei kynnystä-explanation': 'Tililtä saa nostaa rahaa heti, kun on täyttänyt 18 vuotta.',
    'Rajoitettu-explanation': 'Tilin saldo ei voi ylittää 20 000 euroa tai mennä alle nollan euron.',
    'Ei rajoitettu-explanation': 'Tilin saldo voi ylittää 20 000 € tai alittaa 0 €.',
};
