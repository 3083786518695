var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { texts } from '../../texts';
import { getFill } from '../../theme';
import { allClusters } from '../../types';
import { Grid, Legend, LegendItem } from '../common-styled-components';
import ClusterRoleSummaryGraph from './ClusterRoleSummaryGraph';
var GraphWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var ClusterRoleSummaryGrid = function (_a) {
    var summaryData = _a.summaryData, clusters = _a.clusters, showAmounts = _a.showAmounts, hideDetails = _a.hideDetails, hideTitles = _a.hideTitles;
    var keys = ['tyollinen', 'tyoton', 'opiskelija'];
    return (_jsxs("div", { children: [_jsx(Legend, { children: keys.map(function (key) { return (_jsx(LegendItem, { color: getFill(key), text: texts[key] }, key)); }) }, void 0),
            _jsx(Grid, __assign({ full: clusters.length === 1 }, { children: clusters
                    .sort(function (c1, c2) {
                    return allClusters.indexOf(c1) - allClusters.indexOf(c2);
                })
                    .map(function (cluster) { return (_jsx(GraphWrapper, { children: _jsx(ClusterRoleSummaryGraph, { title: hideTitles ? '' : texts[cluster], showAmount: showAmounts, summaryData: summaryData[cluster], keys: keys, 
                        // maxAmount={clusterMaxValue}
                        hideAxis: hideDetails }, void 0) }, cluster)); }) }), void 0)] }, void 0));
};
var templateObject_1;
