import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import { Navigation } from './Navigation';
import { ArticleMain, ArticleSubtitle, P, SectionTitle, TextContent, WideTextContent, } from './common-styled-components';
import { AppContext } from '../context';
import { ClusterAccountUseGrid } from './graphs/ClusterAccountUseGrid';
import { ClusterAccountBalanceGrid } from './graphs/ClusterAccountBalanceGrid';
import { fetchDataSet } from '../data-handlers/resolve-data';
import { ClusterRoleSummaryGrid } from './graphs/ClusterRoleSummaryGrid';
import { Spinner } from './generic/Spinner';
import { texts } from '../texts';
var getDataSetExplanation = function (dataSet, threshold, limitation) {
    console.log(dataSet, threshold, limitation);
    return texts[dataSet + '-explanation'] + " " + texts[threshold + '-explanation'] + " " + texts[limitation + '-explanation'];
};
export default (function () {
    var _a = useContext(AppContext), _b = _a.selections, selectedDataSet = _b.selectedDataSet, selectedThreshold = _b.selectedThreshold, selectedLimitation = _b.selectedLimitation, selectedClusters = _b.selectedClusters, selectedTool = _b.selectedTool, dataSets = _a.dataSets, setDataSets = _a.setDataSets, summaryData = _a.summaryData;
    useEffect(function () {
        if (dataSets &&
            !dataSets[selectedDataSet][selectedThreshold][selectedLimitation]) {
            fetchDataSet(dataSets, setDataSets, selectedDataSet, selectedThreshold, selectedLimitation);
        }
    });
    var baseAccountData = (dataSets[selectedDataSet][selectedThreshold][selectedLimitation] || { baseAccountData: undefined }).baseAccountData;
    // const allData = useMemo(() => flattenData(dataSets), [dataSets]);
    var navigation = (_jsxs(_Fragment, { children: [_jsx(Navigation, { type: "dataSet" }, void 0),
            _jsx(Navigation, { type: "threshold" }, void 0),
            _jsx(Navigation, { type: "limitation" }, void 0),
            _jsx(P, { children: getDataSetExplanation(selectedDataSet, selectedThreshold, selectedLimitation) }, void 0),
            _jsx(Navigation, { type: "clusters" }, void 0)] }, void 0));
    return (_jsxs(ArticleMain, { children: [_jsxs(TextContent, { children: [_jsx(SectionTitle, { children: "Tutki mallia eri parametreilla" }, void 0),
                    _jsx(P, { children: "Perustilimallin parametrej\u00E4 sorvaamalla voi vied\u00E4 perustili\u00E4 toivomaansa suuntaan. Mallin parametreja s\u00E4\u00E4t\u00E4m\u00E4ll\u00E4 saadaan halutessa lis\u00E4tty\u00E4/v\u00E4hennetty\u00E4 tarveharkintaa. Raportissa esitetty simulaatio perustui perusskenaarioon, eli \u201DToteutunut kulutus, korotettu 650 \u20AC opintoraha\u201D. Mallissamme tililt\u00E4 saa nostaa rahaa vasta, kun kynnysehdot (5 opintokuukautta tai 15 000 \u20AC ty\u00F6tulot) ovat t\u00E4yttyneet. Tilin saldo ei voi ylitt\u00E4\u00E4 20 000 euroa tai menn\u00E4 alle nollan euron." }, void 0),
                    _jsx(P, { children: "T\u00E4ll\u00E4 sivulla voit kokeilla miten kynnys (5 opintokuukautta tai 15 000 \u20AC ty\u00F6tulot) vaikuttaa perustilisaldojen kehitykseen. Voit my\u00F6s kokeilla miten perustilin saldo k\u00E4ytt\u00E4ytyisi, jos sit\u00E4 ei rajoitettaisi 0-20\u00A0000 euron liikkumav\u00E4liin." }, void 0),
                    _jsx(Navigation, { type: "tool" }, void 0)] }, void 0),
            _jsx(WideTextContent, { children: selectedTool === 'accountUse' ? (_jsxs(_Fragment, { children: [_jsx(ArticleSubtitle, { children: "Perustilin k\u00E4ytt\u00F6 ryhmitt\u00E4in" }, void 0), navigation, baseAccountData ? (_jsx(ClusterAccountUseGrid, { clusterData: baseAccountData, clusters: selectedClusters }, void 0)) : (_jsx(Spinner, {}, void 0))] }, void 0)) : selectedTool === 'accountBalance' ? (_jsxs(_Fragment, { children: [_jsx(ArticleSubtitle, { children: "Perustilin mediaanisaldo ryhmitt\u00E4in" }, void 0), navigation, baseAccountData ? (_jsx(ClusterAccountBalanceGrid, { clusterData: baseAccountData }, void 0)) : (_jsx(Spinner, {}, void 0))] }, void 0)) : selectedTool === 'roleSummary' ? (_jsxs(_Fragment, { children: [_jsx(ArticleSubtitle, { children: "Opiskelijoiden, ty\u00F6tt\u00F6mien ja ty\u00F6ss\u00E4k\u00E4yvien m\u00E4\u00E4r\u00E4t ryhmitt\u00E4in" }, void 0),
                        _jsx(P, { children: "M\u00E4\u00E4r\u00E4t ylitt\u00E4v\u00E4t ryhmien koot, koska osa-aikaty\u00F6ss\u00E4 olevia opiskelijoita on laskettu samalla ty\u00F6ss\u00E4k\u00E4yviksi." }, void 0),
                        summaryData ? (_jsx(ClusterRoleSummaryGrid, { summaryData: summaryData, clusters: selectedClusters, showAmounts: true }, void 0)) : (_jsx(Spinner, {}, void 0))] }, void 0)) : undefined }, void 0)] }, void 0));
});
